import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, ListSubheader, makeStyles, MenuItem, Select, Typography, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ProgramContext } from "../contexts/ProgramContext";
import styles from "../styles";
import SpmsLogo from "../assets/images/spms-logo.png";
import Background from "../assets/images/background.png";

const useStyles = makeStyles(styles);

const LocationSelect = (props) => {
  const classes = useStyles();
  const { territoriesList, getTerritories, selectedProgramId, setSelectedProgramId } = useContext(ProgramContext);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [programsRearranged, setProgramsRearranged] = useState([]);
  const [programFilter, setProgramFilter] = useState("");

  useEffect(() => {
    getTerritories();
  }, [])

  useEffect(() => {
    const mapsCleaned = territoriesList.map((ter) => 'wilayah' in ter ? ter : { ...ter, ...{ wilayah: 'null' } });

    const mapsGrouped = mapsCleaned.reduce((r,a) => {
      r[a.wilayah] = r[a.wilayah] || [];
      r[a.wilayah].push(a);
      return r;
    }, Object.create(null));

    let mapsRearranged = [];

    for (const [territory, programs] of Object.entries(mapsGrouped)) {
      for (const prog of programs) {
        mapsRearranged.push(prog);
      }
    }
    setProgramsRearranged(mapsRearranged);

    if (territoriesList.length > 0 && selectedProgramId != null) {
      var program = territoriesList.find((ter) => ter.id == selectedProgramId);
      if (program != null) {
        setSelectedProgram(program);
      }
    }
  }, [territoriesList])

  const handleSelectProgram = () => {
    if (selectedProgram == null) {
      return
    }

    localStorage.setItem("programId", selectedProgram.id);
    setSelectedProgramId(selectedProgram.id);
    props.history.push(`/programs/${selectedProgram.id}`);
  }

  return (
    <div className={classes.loginBackground} style={{height: "100%", width: "100%", backgroundImage: `url(${Background})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", marginTop: "-5rem"}}>
      <Helmet>
        <title>Pilihan rancangan</title>
      </Helmet>

      <div className={classes.loginContainer}>
         <img
          src={SpmsLogo}
          alt="logo"
          className={classes.logo}
        />

        <Typography className={classes.bold} style={{marginBottom: "20px"}}>Pilihan rancangan</Typography>
        <Autocomplete
          options={programsRearranged}
          groupBy={(option) => option['wilayah']}
          value={selectedProgram}
          inputValue={programFilter}
          getOptionLabel={(option) => option['name']}
          renderInput={(params) => <TextField
                                     {...params}
                                     className={classes.textInput}
                                     placeholder="Pilihan rancangan"
                                     InputLabelProps={{ ...params.InputLabelProps, shrink: false }}
                                     InputProps={{ ...params.InputProps, disableUnderline: true }}
                                   />
          }
          onChange={(event, val) => {
            setSelectedProgram(val);
            if (val != null) {
              setProgramFilter(val.name)
            } else {
              setProgramFilter("");
            }
          }}
          onInputChange={(event, val) => {
            setProgramFilter(val);
          }}
          style={{width: "100%", marginBottom: "20px", maxHeight: "200px"}}
        />
        <Button style={{width: "100%"}} onClick={() => handleSelectProgram()}>
          Pilih
        </Button>
      </div>
    </div>
  )
}

export default LocationSelect;