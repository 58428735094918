import { createMuiTheme } from "@material-ui/core/styles";
import CircularBook from "./assets/fonts/CircularStd-Book.woff";
import CircularMedium from "./assets/fonts/CircularStd-Medium.woff";
import CircularBold from "./assets/fonts/CircularStd-Bold.woff";
 
const circular = {
 fontFamily: 'Circular',
 fontStyle: 'normal',
 fontDisplay: 'swap',
 fontWeight: 400,
 src: `
    local('Circular'),
    local('CircularStd-Book'),
    url(${CircularBook}) format('woff')
 `,
};

const circularMedium = {
  fontFamily: 'Circular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    local('Circular'),
    local('CircularStd-Medium'),
    url(${CircularMedium}) format('woff')
  `,
 };

 const circularBold = {
  fontFamily: 'Circular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Circular'),
    local('CircularStd-Bold'),
    url(${CircularBold}) format('woff')
  `,
 };

const theme = createMuiTheme({
  typography: {
    fontFamily: "Circular, san-serif"
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [circular, circularMedium, circularBold]
      }
    },
    MuiButton: {
      root: {
        borderRadius: "10px",
        backgroundColor: "#FA9961",
        color: "#FFFFFF",
        '&:hover': {
          backgroundColor: "#FFBE99",
        }
      },
      text: {
        fontFamily: "Circular, san-serif",
        fontWeight: 500,
        fontSize: "1rem",
        textTransform: "none",
        padding: "10px",
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.2)"
      }
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: "#FA9961"
      }
    },
    PrivateSwitchBase: {
      root: {
        padding: 0,
        '&:hover': {
          padding: 0,
          backgroundColor: 'transparent',
        }
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        color: "#FA9961",
        '&$checked': {
          color: "#FA9961",
        }
      }
    }
  }
})

export default theme;