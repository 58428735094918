import React, { createContext, useContext } from "react";
import Axios from "axios";
import { API_ROOT } from "../Config";
import { AuthContext } from "./AuthContext";

const LayerContext = createContext();

const LayerProvider = ({ children }) => {
  const { userToken } = useContext(AuthContext);

  const getLayer = async (id, callback, centroid = false) => {
    let url = `${API_ROOT}/layers/${id}`;

    if (centroid) {
      url = url + "?centroid=1"
    }

    await Axios.get(url, {
      headers: { 'Authorization': 'Bearer ' + userToken }
    })
    .then((response) => {
      if (callback != null) {
        callback(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const updateLayer = async (id, params, callback = null) => {
    await Axios.put(`${API_ROOT}/layers/${id}`, params, {
      headers: { 'Authorization': 'Bearer ' + userToken }
    })
    .then((response) => {
      if (callback != null) {
        callback(response);
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const createLayer = async (type, params, callback = null) => {
    await Axios.post(`${API_ROOT}/layer_types/${type}/layers`, params, {
      headers: { 'Authorization': 'Bearer ' + userToken }
    })
    .then((response) => {
      if (callback != null) {
        callback(response.data.data.id);
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }

  return (
    <LayerContext.Provider
      value={{
        getLayer,
        updateLayer,
        createLayer
      }}
    >
      { children }
    </LayerContext.Provider>
  )
}

export { LayerContext, LayerProvider };
