import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import theme from "./theme";
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './contexts/AuthContext';
import { MapProvider } from './contexts/MapContext';
import { ProgramProvider } from './contexts/ProgramContext';
import { LotProvider } from './contexts/LotContext';
import { LevelProvider } from './contexts/LevelContext';
import { BlockProvider } from './contexts/BlockContext';
import { LayerTypeProvider } from './contexts/LayerTypeContext';
import { LayerProvider } from './contexts/LayerContext';
import { ChangeProvider } from './contexts/ChangeContext';
import { QueryProvider } from './contexts/QueryContext';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <LayerTypeProvider>
        <MapProvider>
          <LayerProvider>
            <ProgramProvider>
              <LevelProvider>
                <BlockProvider>
                  <LotProvider>
                    <ChangeProvider>
                      <QueryProvider>
                        <ThemeProvider theme={theme}>
                          <CssBaseline />
                          <HelmetProvider>
                            <App />
                          </HelmetProvider>
                        </ThemeProvider>
                      </QueryProvider>
                    </ChangeProvider>  
                  </LotProvider>
                </BlockProvider>  
              </LevelProvider>  
            </ProgramProvider>
          </LayerProvider>
        </MapProvider>
      </LayerTypeProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
