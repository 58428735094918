import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Checkbox, makeStyles, TextField, Typography } from "@material-ui/core";
import { ROOT } from "src/Config";
import styles from "../styles";
import SpmsLogo from "../assets/images/spms-logo.png";
import Background from "../assets/images/background.png";
import { AuthContext } from "../contexts/AuthContext";
import { ProgramContext } from "../contexts/ProgramContext";

const useStyles = makeStyles(styles);

const ForgotPassword = (props) => {
  const classes = useStyles();
  const { forgotPassword } = useContext(AuthContext);
  const [username, setUsername] = useState(null);
  const [notiMessage, setNotiMessage] = useState({ message: null, type: null });

  const handleForgotPassword = () => {
    setNotiMessage({ message: null, type: null });
    let params = { username: username }
    forgotPassword(params, () => {
    	setNotiMessage({ message: "Successfully requested password reset", type: "success" })
    }, (error) => { setNotiMessage({ message: error, type: "error" })
  	});
  }

  return (
    <div className={classes.loginBackground} style={{height: "100%", width: "100%", backgroundImage: `url(${Background})`, backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
    
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>

      <div className={classes.loginContainer}>
      	<Typography className={classes.heading} style={{marginBottom: "15px"}}>Lupa Kata Laluan</Typography>

        <Typography className={classes.bold} style={{marginBottom: "5px"}}>ID/No kakitangan</Typography>
        <TextField
          placeholder="ID/No kakitangan"
          className={classes.textInput}
          InputProps={{ disableUnderline: true }}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={{marginBottom: "20px"}}
        />        

        <div style={{height: "50px"}}>
          {
            notiMessage.message != null && (
              <Typography className={notiMessage.type == "success" ? classes.success : classes.error}>{ notiMessage.message }</Typography>
            )
          }
        </div>
        <Button onClick={handleForgotPassword}>
          Hantar
        </Button>

        <Typography className={classes.link} style={{marginTop: "20px"}} onClick={() => props.history.push('/login')}>Daftar Masuk</Typography>
      </div>
    </div>
  )
}

export default ForgotPassword;