import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Checkbox, makeStyles, TextField, Typography } from "@material-ui/core";
import { ROOT } from "src/Config";
import styles from "../styles";
import SpmsLogo from "../assets/images/spms-logo.png";
import Background from "../assets/images/background.png";
import { AuthContext } from "../contexts/AuthContext";
import { ProgramContext } from "../contexts/ProgramContext";

const useStyles = makeStyles(styles);

const Login = (props) => {
  const classes = useStyles();
  const { isAuthenticated, user, autoLogin, login } = useContext(AuthContext);
  const { setSelectedProgramId } = useContext(ProgramContext);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    autoLogin();
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      if (user.permissions.some((permission) => permission.includes("map:ListMaps"))) {
        const storedProgramId = localStorage.getItem("programId");

        if (storedProgramId != null) {
          setSelectedProgramId(storedProgramId)
          props.history.push(`/programs/${storedProgramId}`);
        } else {
          props.history.push("/programs/select");
        }
      } else if (user.permissions.some((permission) => permission.includes("map:GetMap"))) {
        let firstMapId = user.permissions.find((permission) => permission.includes("map:GetMap")).split("map:GetMap.gaia:map:::")[1];
        if (firstMapId == "*") {
          props.history.push("/programs/select");
        } else {
          setSelectedProgramId(firstMapId)
          props.history.push(`/programs/${firstMapId}`);
        }
      } else if (user.permissions.some((permission) => permission.includes("*:*"))) {
        window.location.replace(`${ROOT}/console`);
      }
    }
  }, [isAuthenticated])

  const handleLogin = () => {
    setErrorMessage(null);
    const params = {
      username: username,
      password: password,
      remember_me: rememberMe
    }
    login(params, setErrorMessage);
  }

  return (
    <div className={classes.loginBackground} style={{height: "100%", width: "100%", backgroundImage: `url(${Background})`, backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
    
      <Helmet>
        <title>Login</title>
      </Helmet>

      <div className={classes.loginContainer}>
        <img
          src={SpmsLogo}
          alt="logo"
          className={classes.logo}
        />

        <Typography className={classes.bold} style={{marginBottom: "5px"}}>ID/No kakitangan</Typography>
        <TextField
          placeholder="ID/No kakitangan"
          className={classes.textInput}
          InputProps={{ disableUnderline: true }}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={{marginBottom: "20px"}}
        />
        <Typography className={classes.bold} style={{marginBottom: "5px"}}>Kata laluan</Typography>
        <TextField
          type="password"
          placeholder="Kata laluan"
          className={classes.textInput}
          InputProps={{ disableUnderline: true }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{marginBottom: "20px"}}
        />

        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <Checkbox
            disableRipple
            color="primary"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <Typography style={{marginLeft: "5px"}}>Ingat saya</Typography>
        </div>
        

        <div style={{height: "50px"}}>
          {
            errorMessage != null && (
              <Typography className={classes.error}>{ errorMessage }</Typography>
            )
          }
        </div>
        <Button onClick={() => handleLogin()}>
          Daftar masuk
        </Button>

        <Typography className={classes.link} style={{marginTop: "20px"}} onClick={() => props.history.push('/forgot_password')}>Lupa kata laluan</Typography>
      </div>
      
    
    </div>
  )
}

export default Login;