const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
  },
  main: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    minHeight: "100vh",
    boxSizing: "border-box"
  },
  loginBackground: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  loginContainer: {
    width: "420px",
    backgroundColor: "#FFFFFF",
    borderRadius: "20px",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box"
  },
  logo: {
    width: "260px",
    marginBottom: "20px",
    alignSelf: "center"
  },
  whiteBar: {
    position: "fixed",
    width: "100vw",
    height: "5rem",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 4px -2px rgba(0,0,0,.2)",
    zIndex: "800",
    paddingLeft: "50px",
    paddingRight: "50px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  navLogo: {
    height: "3rem",
    width: "auto",
    objectFit: "contain",
    cursor: "pointer"
  },
  nodeInsidePanel: {
    backgroundColor: "#137CBD",
    borderRadius: "3px",
    height: "40px",
    width: "40px",
    display: "flex",
    cursor: "grab",
    alignItems: "center",
    justifyContent: "center"
  },
  nodeRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px"
  },
  privateMain: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: "#FFFFFF",
    minHeight: "100vh",
    boxSizing: "border-box",
    paddingTop: "5rem",
  },
  page: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  sideContainer: {
    backgroundColor: "#FFFFFF",
    position: "absolute",
    width: "500px",
    height: "calc(100vh - 5rem)",
    boxShadow: "2px 0 4px -2px rgba(0,0,0,.1)",
    /* overflow: "scroll" */
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    background: "#FFFFFF",
    width: "550px",
    minHeight: "calc(100vh - 50px)",
    boxShadow: "1px 0 1px rgba(16, 22, 26, 0.1), -1px 0 1px rgba(16, 22, 26, 0.1)"
  },
  textInput: {
    backgroundColor: "#F0F1F5",
    padding: "10px",
    borderRadius: "10px",
  },
  searchBox: {
    marginLeft: "70px",
    width: "450px",
    display: "flex",
    backgroundColor: "#F0F1F5",
    borderRadius: "10px",
    alignItems: "center",
    paddingLeft: "10px",
    boxShadow: "none"
  },
  bold: {
    fontWeight: 500
  },
  error: {
    fontWeight: 500,
    color: "#FA9961"
  },
  success: {
    fontWeight: 500,
    color: "green"
  },
  secondary: {
    color: "#717171"
  },
  body: {
    fontSize: "1rem"
  },
  heading: {
    fontWeight: 500,
    fontSize: "1.6rem"
  },
  tinyHeading: {
    fontWeight: 400,
    fontSize: "0.9rem"
  },
  subheading: {
    fontWeight: 500,
    fontSize: "1.2rem"
  },
  link: {
    color: "#000000",
    cursor: "pointer",
    "&:hover": {
      color: "#717171"
    }
  },
  info: {
    fontWeight: 500,
    fontSize: "1.4rem"
  },
  infoTable: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  infoTableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderBottom: "3px solid #F0F1F5"
  },
  infoTableColumn: {
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  searchCategories: {
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingRight: "10px",
    paddingLeft: "10px",
    borderBottom: "3px solid #F0F1F5",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.04)"
    }
  },
  sphContainer: {
    width: "80px",
    padding: "5px 8px 5px 8px",
    borderRadius: "8px",
    alignItems: "center"
  },
  sphTitle: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "#FFFFFF",
    textAlign: "center"
  },
  sphText: {
    fontSize: "1.4rem",
    fontWeight: 500,
    color: "#FFFFFF",
    textAlign: "center"
  },
  searchResult: {
    paddingTop: "15px",
    paddingBottom: "15px",
    borderBottom: "3px solid #F0F1F5",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer"
  },
  rowWithTwoColumns: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "10px"
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: "500px",
    minHeight: "300px",
    padding: "10px 20px 10px 20px",
    backgroundColor: "#FFFFFF",
    borderRadius: "15px"
  },
  modalTitle: {
    flex: 4,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  marker: {
    width: "6px",
    height: "6px",
    borderRadius: "3px",
    cursor: "pointer"
  },
  popupContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: "#FFFFFF",
    padding: "0 5px 0 5px"
  },
  popupTitle: {
    width: "100%",
    display:"flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px"
  },
  popupButtons: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    width: "100%",
    justifyContent: "space-between"
  },
  textButton: {
    fontWeight: 500,
    textAlign: "center",
    color: "#FA9961",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "FFBE99"
    }
  },
  territoryListDropdown: {
    maxHeight: 250,
    "&::-webkit-scrollbar": {
      width: "10px",
      background: "transparent"
    },
    '&::-webkit-scrollbar-track': {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "5px"
    }
  },
  lotNumberMarker: {
    width: 40,
    height: 20,
    textAlign: 'center',
    backgroundColor: 'white'
  },
  diffRow: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "5px"
  },
  positiveDelta: {
    color: "#676C67",
    backgroundColor: "#DAF9DB",
    padding: "2px 4px",
    borderRadius: "4px"
  },
  negativeDelta: {
    marginRight: "5px",
    color: "#898683",
    backgroundColor: "#FEDFDB",
    padding: "2px 4px",
    borderRadius: "4px"
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      height: 0
    }
  },
  MuiAccordionSummaryRoot: {
    "&.MuiAccordionSummary-root": {
      padding: 0
    }
  },
  MuiAccordionDetailsRoot: {
    "&.MuiAccordionDetails-root": {
      padding: 0
    }
  }
}

export default styles;
