import React from 'react';
import { makeStyles, Typography } from "@material-ui/core";

import styles from "src/styles";

const useStyles = makeStyles(styles);

const SphLegendItem = ({ color, description }) => {
  const classes = useStyles();

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ width: 40, height: 40, borderRadius: 5, backgroundColor: color, marginBottom: 10 }} />
      <Typography className={classes.secondary}>{description}</Typography>
    </div>
  )
}

export default SphLegendItem;
