import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, makeStyles, Typography, TextField } from "@material-ui/core";
import styles from "../styles";
import { AuthContext } from "src/contexts/AuthContext";

const useStyles = makeStyles(styles);

const Account = (props) => {
  const classes = useStyles();
  const { user, updatePassword } = useContext(AuthContext);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [notiMessage, setNotiMessage] = useState({ message: null, type: null });

  const handleUpdatePassword = () => {
    let params = {
      old_password: oldPassword,
      new_password: newPassword,
      new_password_confirmation: newPasswordConfirmation
    }

    updatePassword(params, () => {
      setNotiMessage({ message: "Successfully updated password", type: "success" });
      setOldPassword("");
      setNewPassword("");
      setNewPasswordConfirmation("");
    }, (error) => { setNotiMessage({ message: error, type: "error" })
    })
  }

  return (
    <div className={classes.page}>
      <div style={{paddingLeft: "40px",paddingRight: "40px",paddingTop: "20px", display: "block"}}>
        <Typography className={classes.heading} style={{marginBottom: "20px"}}>Tetapan Akaun</Typography>

        <Typography className={classes.subheading} style={{marginBottom: "20px"}}>Tukar kata laluan</Typography>

        <Typography className={classes.bold} style={{marginBottom: "5px"}}>Kata laluan lama</Typography>
        <TextField
          type="password"
          placeholder="Kata laluan lama"
          className={classes.textInput}
          InputProps={{ disableUnderline: true }}
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          style={{marginBottom: "20px"}}
        />

        <Typography className={classes.bold} style={{marginBottom: "5px"}}>Kata laluan baru</Typography>
        <TextField
          type="password"
          placeholder="Kata laluan baru"
          className={classes.textInput}
          InputProps={{ disableUnderline: true }}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          style={{marginBottom: "20px"}}
        />

        <Typography className={classes.bold} style={{marginBottom: "5px"}}>Pengesahan kata laluan baru</Typography>
        <TextField
          type="password"
          placeholder="Kata laluan baru"
          className={classes.textInput}
          InputProps={{ disableUnderline: true }}
          value={newPasswordConfirmation}
          onChange={(e) => setNewPasswordConfirmation(e.target.value)}
          style={{marginBottom: "20px"}}
        />

        <div style={{height: "50px"}}>
          {
            notiMessage.message != null && (
              <Typography className={notiMessage.type == "success" ? classes.success : classes.error}>{ notiMessage.message }</Typography>
            )
          }
        </div>

        <Button onClick={handleUpdatePassword}>
          Kemaskini
        </Button>
      </div>
    </div>
  )
}

export default Account;