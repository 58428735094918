import { makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState, useCallback, useRef } from "react";
import ReactMapGL, { Source, Layer, Popup } from 'react-map-gl';
import maplibre from 'maplibre-gl/dist/maplibre-gl';
import MaplibreWorker from 'maplibre-gl/dist/maplibre-gl-csp-worker';
import { Editor, EditingMode } from "react-map-gl-draw";
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom'
import length from "@turf/length";
import area from "@turf/area";
import { MapContext } from "../contexts/MapContext";
import styles from "../styles";
import { ProgramContext } from "../contexts/ProgramContext";
import polylabel from 'polylabel'
import { LayerContext } from "src/contexts/LayerContext";
import { LayerTypeContext } from "src/contexts/LayerTypeContext";
import findLayerType from "src/utils/findLayerType";
import IssueModal from './IssueModal';
import NewLayerModal from "./NewLayerModal";
import createKML from "src/utils/createKML";

maplibre.workerClass = MaplibreWorker.default;

let satelliteBaseMap = require('../basemaps/satellite.json');
var slugid = require('slugid');

const useStyles = makeStyles(styles);

const Map = () => {
  const classes = useStyles();
  const {
    viewport,
    drawingMode,
    drawingModeId,
    newLayerMode,
    newLayerCoordinate,
    mainLayersUrls,
    infraLayersUrls,
    setViewport,
    setDrawingModeId,
    setDrawingMode,
    setNewLayerCoordinate,
    showCulvert,
    showRiver,
    showMainRoad,
    showDirtRoad,
    showDirtTrail,
    searchedCoordinate,
    flyTo,
    layerToShowIds
  } = useContext(MapContext);
  const history = useHistory();
  const location = useLocation();
  const matchLot = useRouteMatch("/programs/:program_id/lots/:lot_layer_name/:lot");
  const matchBlock = useRouteMatch("/programs/:program_id/blocks/:block");
  const matchLevel = useRouteMatch("/programs/:program_id/levels/:level");
  const matchUrl = useMemo(() => (matchLot || matchBlock || matchLevel), [location]);
  const mapRef = useRef(null);
  const { program, programMosaicBucketFolders } = useContext(ProgramContext);
  const { getLayerTypes, layerTypes } = useContext(LayerTypeContext);
  const { getLayer } = useContext(LayerContext);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [drawnFeatures, setDrawnFeatures] = useState([]);
  const [selectedFeatureSize, setSelectedFeatureSize] = useState(null);
  const [selectableLayerTypes, setSelectableLayerTypes] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [newLayerModalOpen, setNewLayerModalOpen] = useState(false);
  const [selectableNewLayerTypes, setSelectableNewLayerTypes] = useState([]);
  const [hoverInfo, setHoverInfo] = useState(null);
  const [interactiveLayerIds, setInteractiveLayerIds] = useState([]);
  const prevHoverId = useRef();

  useEffect(() => {
    if (searchedCoordinate.length > 0) {
      const newDrawnFeatures = drawnFeatures.filter((item) => item.geometry?.type !== 'Point');
      newDrawnFeatures.push({
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [searchedCoordinate[0], searchedCoordinate[1]],
        }
      })
      setDrawnFeatures(newDrawnFeatures);
      flyTo(searchedCoordinate[0], searchedCoordinate[1], 17)
      setDrawingModeId('editing')
      setDrawingMode(new EditingMode())
    }
  }, [searchedCoordinate])

  useEffect(() => {
    if (program != null) {
      getLayerTypes(program.id, (newLayerTypes) => {
        if (newLayerTypes.length > 0) {
          var list = [];
          var newList = [];

          ['tree_point', 'unhealthy_point', 'unhealthy_point_in_progress', 'vacant_point'].forEach((x, i) => {
            const item = findLayerType(x, newLayerTypes);

            if (item != null) {
              list.push(item)
            }
          });

          ['others', 'elephant_fence', 'energized_fence', 'watchtower', 'water_source', 'gazebo', 'bridge'].forEach((x, i) => {
            const item = findLayerType(x, newLayerTypes);

            if (item != null) {
              newList.push(item)
            }
          });

          setSelectableLayerTypes(list);
          setSelectableNewLayerTypes(newList);
        }
      });
    }
  }, [program])

  useEffect(() => {
    if (!!mapRef.current) {
      const map = mapRef.current.getMap();
      if (prevHoverId.current != null) {
        map.removeFeatureState({
          source: prevHoverId.current.source,
          id: prevHoverId.current.id
        })
      }
      if (hoverInfo != null) {
        map.setFeatureState(
          {
            source: hoverInfo.source,
            id: hoverInfo.id
          },
          {
            hover: true
          }
        )
      }
    }

    if (hoverInfo != null) {
      prevHoverId.current = { source: hoverInfo.source, hoverId: hoverInfo.id }
    } else {
      prevHoverId.current = null
    }
  }, [hoverInfo])

  const handleSelectPoint = (id) => {
    getLayer(id, (point) => setSelectedIssue(point), true)
    setModalOpen(true)
  }

  const handleSelectFeature = ({ selectedFeature, selectedFeatureIndex, mapCoords }) => {
    const selectedGeometryType = selectedFeature != null && selectedFeature.geometry.type;

    if (newLayerMode) {
      setNewLayerCoordinate(mapCoords);
      setNewLayerModalOpen(true);
    }

    if (selectedGeometryType) {
      const selectedGeometry = selectedFeature.geometry;

      if ((drawingModeId === 'drawLine' || drawingModeId === 'editing') && selectedGeometryType === "LineString") {
        const lastPoint = selectedGeometry.coordinates[selectedGeometry.coordinates.length - 1]
        const feature = {
          index: selectedFeatureIndex,
          longitude: lastPoint[0],
          latitude: lastPoint[1],
          size: length(selectedFeature).toFixed(3),
          type: 'LineString'
        }

        setSelectedFeatureSize(feature);
      }

      if ((drawingModeId === 'drawPolygon' || drawingModeId === 'editing') && selectedGeometryType === "Polygon") {
        const centroid = polylabel(selectedGeometry.coordinates, 1.0);
        const areaInHectare = area(selectedFeature) * 0.0001;
        const feature = {
          index: selectedFeatureIndex,
          longitude: centroid[0],
          latitude: centroid[1],
          size: areaInHectare.toFixed(3),
          type: 'Polygon'
        }
        setSelectedFeatureSize(feature);
      }

      if (
        selectedGeometryType === 'Point' &&
        selectedGeometry.coordinates[0] === searchedCoordinate[0] &&
        selectedGeometry.coordinates[1] === searchedCoordinate[1]
      ) {
        const feature = {
          index: selectedFeatureIndex,
          longitude: searchedCoordinate[0],
          latitude: searchedCoordinate[1],
          size: null,
          type: 'Point'
        }
        setSelectedFeatureSize(feature);
      }

    } else {
      setSelectedFeatureSize(null);
    }
  }

  const handleDrawnFeaturesUpdate = ({ data, editType }) => {
    setDrawnFeatures(data);
    if (editType == "addFeature") {
      setDrawingModeId("editing");
      setDrawingMode(new EditingMode());
    }
  }

  const handleDeleteDrawnFeature = (selectedIndex) => {
    if (selectedIndex >= 0) {
      const newDrawnFeatures = [...drawnFeatures];
      newDrawnFeatures.splice(selectedIndex, 1);
      setSelectedFeatureSize(null);
      setDrawnFeatures(newDrawnFeatures);
    }
  }

  const handleDownloadDrawnFeature = (selectedIndex) => {
    var kmlString = createKML(drawnFeatures[selectedIndex].geometry.coordinates, drawnFeatures[selectedIndex].geometry.type);
    var filename = "feature.kml";
    var pom = document.createElement('a');
    var bb = new Blob([kmlString], {type: 'text/plain'});
    pom.setAttribute('href', window.URL.createObjectURL(bb));
    pom.setAttribute('download', filename);
    pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
    pom.draggable = true;
    pom.classList.add('dragout');
    pom.click();
  }

  const onHover = useCallback(event => {
    const { features, srcEvent: {offsetX, offsetY} } = event;
    const hoveredFeature = features && features[0];
    let newHoverInfo = null;
    if (hoveredFeature) {
      newHoverInfo = {
        id: hoveredFeature.id,
        x: offsetX,
        y: offsetY,
        source: hoveredFeature.source
      }
    }
    setHoverInfo(newHoverInfo);
  }, []);


  const onClick = (event) => {
    if (hoverInfo) {
      const layerType = findLayerType(hoverInfo.source, layerTypes, 'id')?.key;

      if (layerType === 'block' || layerType === 'level') {
        history.push(`/programs/${program.id}/${layerType + 's'}/${slugid.decode(hoverInfo.id)}`);
      } else if (layerType === 'lot') {
        history.push(`/programs/${program.id}/lots/peneroka/${slugid.decode(hoverInfo.id)}`);
      } else if (layerType === 'felda_lot') {
        history.push(`/programs/${program.id}/lots/felda/${slugid.decode(hoverInfo.id)}`);
      } else if (layerType === 'tree_point' || layerType === 'unhealthy_point' || layerType ===  'unhealthy_point_in_progress' || layerType === 'vacant_point') {
        handleSelectPoint(slugid.decode(hoverInfo.id))
      }
    }
  }

  useEffect(() => {
    if (mainLayersUrls && program?.id) {
      let newMainLayersUrls = [...mainLayersUrls];
      if (matchUrl) {
        delete matchUrl.params.program_id;
        delete matchUrl.params.lot_layer_name;
        const layerKeyToRemove = Object.keys(matchUrl.params)[0];
        const filteredLayers = newMainLayersUrls.filter((item) => item.key !== layerKeyToRemove);
        newMainLayersUrls = [...filteredLayers];
      }
      const layerIds = newMainLayersUrls.map((item) => item.id);
      setInteractiveLayerIds(layerIds)
    }
  }, [mainLayersUrls, matchUrl])

  const filter = useMemo(() => ['in', 'id', hoverInfo?.id], [hoverInfo]);

  return (
    <ReactMapGL
      {...viewport}
      ref={mapRef}
      width={"100%"}
      height={"100%"}
      mapStyle={satelliteBaseMap}
      onViewportChange={setViewport}
      transitionDuration={0}
      onHover={onHover}
      onClick={onClick}
      interactiveLayerIds={interactiveLayerIds}
    >
      <Source
        id="empty"
        type="geojson"
        data={{ type: 'FeatureCollection', features: [] }}
      >
        <Layer
          id="dummy-1"
          type="symbol"
          source="empty"
        />
        <Layer
          id="dummy-2"
          type="symbol"
          source="empty"
          beforeId="dummy-1"
        />
        <Layer
          id="dummy-3"
          type="symbol"
          source="empty"
          beforeId="dummy-2"
        />
      </Source>
      {
        program != null && programMosaicBucketFolders.length > 0 && (
          programMosaicBucketFolders.map((folder, index) => (
            <Source
              key={`${program.id}-${index}`}
              id={`${program.id}-${index}`}
              type="raster"
              tiles={[folder]}
              tileSize={256}
              scheme="tms"
            >
              <Layer
                id={`${program.id}-${index}`}
                type="raster"
                source={`${program.id}-${index}`}
                minZoom={0}
                maxZoom={22}
                beforeId="dummy-3"
              />
            </Source>
          ))
        )
      }
      {
        program != null && mainLayersUrls.map((main) => (
          <Source
            key={main.id}
            id={main.id}
            type="geojson"
            data={main.url}
            promoteId={"id"}
          >
            <Layer
              key={main.id}
              id={main.id}
              type={main.type}
              source={main.id}
              layout={{ visibility: layerToShowIds.find((item) => item === main.id) ? 'visible' : 'none' }}
              paint={main.paint}
              beforeId={(main.key == 'level' || main.key == 'block' || main.key == 'lot' || main.key == 'felda_lot') ? "dummy-2" : (main.key == 'tree_point' || main.key == 'unhealthy_point' || main.key == 'unhealthy_point_in_progress' || main.key == 'vacant_point') ? "dummy-1" : null }
            />
          </Source>
        ))
      }
      {
        program != null && infraLayersUrls.map((infra) => (
          <Source
            key={infra.id}
            id={infra.id}
            type="geojson"
            data={infra.url}
            promoteId={"id"}
          >
            <Layer
              key={infra.id}
              id={infra.id}
              type={infra.type}
              source={infra.id}
              layout={{'visibility':  layerToShowIds.find((item) => item === infra.id) ? 'visible' : 'none'}}
              paint={infra.paint}
            />
          </Source>
        ))
      }
      { selectedIssue != null && (
        <IssueModal
          setSelectedIssue={setSelectedIssue}
          selectedIssue={selectedIssue}
          selectableLayerTypes={selectableLayerTypes}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      ) }

      {
        drawingMode && <Editor
          features={drawingModeId !== 'newLayer' ? drawnFeatures : []}
          clickRadius={12}
          mode={drawingMode}
          onSelect={handleSelectFeature}
          onUpdate={handleDrawnFeaturesUpdate}
          editHandleShape={"circle"}
        />
      }


      {
        newLayerCoordinate != null && (
          <NewLayerModal
            setNewLayerCoordinate={setNewLayerCoordinate}
            newLayerCoordinate={newLayerCoordinate}
            selectableLayerTypes={selectableNewLayerTypes}
            modalOpen={newLayerModalOpen}
            setModalOpen={setNewLayerModalOpen}
          />
        )
      }
      {
        selectedFeatureSize != null && (
          <Popup
            longitude={selectedFeatureSize.longitude}
            latitude={selectedFeatureSize.latitude}
            onClose={() => setSelectedFeatureSize(null)}
            closeOnClick={false}
            closeButton={false}
          >
            {
              selectedFeatureSize.type === 'Point' && (
                <div style={{ textAlign: 'center' }}>
                  <Typography className={classes.body}>Koordinat dicari</Typography>
                  <Typography className={classes.body}>{ `${selectedFeatureSize.longitude}, ${selectedFeatureSize.latitude}` }</Typography>
                </div>
              )
            }
            {
              selectedFeatureSize.size &&
              <Typography className={classes.body} style={{textAlign: "center"}}>
                {
                  `${selectedFeatureSize.size}
                  ${selectedFeatureSize.type === 'LineString' ? 'km' : 'ha'}`
                }
              </Typography>
            }
            {
              selectedFeatureSize.type != 'Point' && (
                <Typography className={classes.textButton} style={{marginTop: "10px", marginBottom: "5px"}} onClick={() => handleDownloadDrawnFeature(selectedFeatureSize.index)}>Download KML</Typography>
              )
            }
            <Typography className={classes.textButton} onClick={() => handleDeleteDrawnFeature(selectedFeatureSize.index)}>Delete</Typography>
          </Popup>
        )
      }
    </ReactMapGL>
  )
}

export default Map;
