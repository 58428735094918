import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, useLocation } from "react-router-dom";
import { Button, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { FaSlidersH } from "react-icons/fa";
import Lottie from 'react-lottie';
import styles from "../../styles";
import { LevelContext } from "../../contexts/LevelContext";
import { MapContext } from "../../contexts/MapContext";
import SphCard from "../../components/SphCard";
import Modal from "../../components/Modal";
import Chip from "../../components/Chip";
import loadingAnimation from "../../assets/images/loading.json";
import findLayerType from "src/utils/findLayerType";
import { LayerTypeContext } from "src/contexts/LayerTypeContext";

const useStyles = makeStyles(styles);

const Levels = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const page = parseInt(query.get("page")) || 1;
  const sphFilter = query.get("sph");
  const keyword = query.get("keyword");
  const { levels, levelsTotalCount, levelsTotalPages, getLevels } = useContext(LevelContext);
  const { getLayerTypes } = useContext(LayerTypeContext);
  const { getLevelsTiles } = useContext(MapContext);
  const [loading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [sphGoodFilter, setSphGoodFilter] = useState(false);
  const [sphMediumFilter, setSphMediumFilter] = useState(false);
  const [sphBadFilter, setSphBadFilter] = useState(false);
  const [layerTypeId, setLayerTypeId] = useState(null);

  useEffect(() => {
    if (layerTypeId != null) {
      fetchLevels(layerTypeId);
    } else {
      getLayerTypes(id, (newLayerTypes) => {
        if (newLayerTypes.length > 0) {
          const foundLayerType = findLayerType('level', newLayerTypes);
          setLayerTypeId(foundLayerType.id);
          fetchLevels(foundLayerType.id);
        }
      });
    }
  }, [page, sphFilter, keyword])

  useEffect(() => {
    if (sphGoodFilter) {
      setSphMediumFilter(false);
      setSphBadFilter(false);
    }
  }, [sphGoodFilter])

  useEffect(() => {
    if (sphMediumFilter) {
      setSphGoodFilter(false);
      setSphBadFilter(false);
    }
  }, [sphMediumFilter])

  useEffect(() => {
    if (sphBadFilter) {
      setSphGoodFilter(false);
      setSphMediumFilter(false);
    }
  }, [sphBadFilter])

  const fetchLevels = (typeId) => {
    let filter = null;

    if (sphFilter == "good" || sphFilter == "medium" || sphFilter == "bad") {
      switch (sphFilter) {
        case ("good"):
          setSphGoodFilter(true);
          break
        case ("medium"):
          setSphMediumFilter(true);
          break
        case ("bad"):
          setSphBadFilter(true);
          break
      }
      filter = sphFilter;
    }

    setLoading(true);
    getLevels(typeId, page, filter, keyword, (newLevels) => {
      var newLevelsIds = newLevels.map((newLevel) => newLevel.id);
      getLevelsTiles(id, newLevelsIds);
      setLoading(false);
    });
  }

  const handleChangePage = (event, value) => {
    let url = `/programs/${id}/levels?page=${value}`;

    if (sphFilter != null && (sphFilter == "good" || sphFilter == "medium" || sphFilter == "bad")) {
      url = url + `&sph=${sphFilter}`;
    }

    if (keyword != null && keyword != "") {
      url = url + `&keyword=${keyword}`;
    }

    props.history.push(url);
  }

  const handleClearFilter = () => {
    setSphGoodFilter(false);
    setSphMediumFilter(false);
    setSphBadFilter(false);
  }

  const handleApplyFilter = () => {
    let url = `/programs/${id}/levels?page=1`;

    if (sphGoodFilter) {
      url = url + '&sph=good'
    } else if (sphMediumFilter) {
      url = url + '&sph=medium'
    } else if (sphBadFilter) {
      url = url + '&sph=bad'
    }

    if (keyword != null && keyword != "") {
      url = url + `&keyword=${keyword}`;
    }

    props.history.push(url);
    setShowFilters(false);
  }

  return (
    <>
      <Helmet>
        <title>Carian Peringkat</title>
      </Helmet>

      <div style={{paddingLeft: "40px",paddingRight: "40px",paddingTop: "20px"}}>
        {
          loading ? (
            <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: loadingAnimation,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
                height={200}
                width={200}
            />
          ) : (
            <>
              <div style={{marginBottom: "10px", alignItems: "center", justifyContent: "space-between", display: "flex", flexDirection: "row"}}>
                <Typography className={classes.bold}>{`${levelsTotalCount} Peringkat`}</Typography>
                <IconButton onClick={() => setShowFilters(true)}>
                  <FaSlidersH size={"1.3rem"} color={"#FA9961"} />
                </IconButton>
              </div>
              <div style={{height: "calc(100vh - 5rem - 80px)", overflow: "scroll"}}>
                {
                  levels.map((level, index) => (
                    <div key={index} onClick={() => props.history.push(`/programs/${id}/levels/${level.id}`)} className={classes.searchResult} style={{borderTop: index == 0 ? "3px solid #F0F1F5" : null}}>
                      <div style={{display: "flex", flexDirection: "column"}}>
                        <Typography className={classes.info}>{ level.name }</Typography>
                        <Typography className={classes.secondary}>{`${level.tree_count} pokok · ${level.unhealthy_count} tidak sihat · ${level.vacant_point_count} dirian kosong`}</Typography>
                      </div>
                      
                      <SphCard sph={level.sph} />
                    </div>
                  ))
                }
                <div style={{marginTop: "20px", marginBottom: "20px", display: "flex", justifyContent: "center"}}>
                  <Pagination page={page} count={levelsTotalPages} onChange={handleChangePage}/>
                </div>
              </div>
            </>
          )
        }
      </div>

      <Modal open={showFilters} onClose={() => setShowFilters(false)} title={"Tapis"}>
        <Typography className={classes.subheading}>SPH</Typography>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", paddingTop: "10px"}}>
          <Chip label={"Bagus"} active={sphGoodFilter} callback={() => setSphGoodFilter(!sphGoodFilter)}/>
          <Chip label={"Sederhana"} active={sphMediumFilter} callback={() => setSphMediumFilter(!sphMediumFilter)} style={{marginLeft: "10px"}}/>
          <Chip label={"Kurang baik"} active={sphBadFilter} callback={() => setSphBadFilter(!sphBadFilter)} style={{marginLeft: "10px"}}/>
        </div>
        <div style={{marginTop: "10px"}}>
          <Typography className={classes.link} onClick={handleClearFilter}>Clear</Typography>
        </div>
        
        <Button style={{position: "absolute", bottom: 20, left: 20, right: 20}} onClick={() => handleApplyFilter()}>Apply</Button>
      </Modal>
    </>
  )
}

export default Levels;