import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, makeStyles, Typography } from "@material-ui/core";
import styles from "../styles";
import { ProgramContext } from "src/contexts/ProgramContext";
import { QueryContext } from "src/contexts/QueryContext";

const useStyles = makeStyles(styles);

const reportKeys = [
  {
    name: "Laporan Peringkat",
    key: "level_report_query_id",
  },
  {
    name: "Laporan Blok",
    key: "block_report_query_id",
  },
  {
    name: "Laporan Lot",
    key: "lot_report_query_id",
  },
  {
    name: "Laporan Lapisan Lain",
    key: "layers_report_query_id"
  }
]

const Queries = (props) => {
  const classes = useStyles();
  const { program_id } = useParams();
  const { program, getProgram } = useContext(ProgramContext);
  const { downloadQuery } = useContext(QueryContext);

  useEffect(() => {
    if (program_id == null) {
      props.history.push("/programs/select");
      return
    }

    getProgram(program_id);
  }, [])

  const handleDownload = (id) => {
    downloadQuery(id);
  }

  return (
    <div className={classes.page}>
      <div style={{paddingLeft: "40px",paddingRight: "40px",paddingTop: "20px"}}>
        <Typography className={classes.heading} style={{marginBottom: "20px"}}>Laporan</Typography>

        { program != null && (
          <>
            {
              (program['level_report_query_id'] != null || program['block_report_query_id'] != null || program['lot_report_query_id'] != null) ? (
                <>
                  {
                    reportKeys.map((reportKey) => 
                      program[reportKey.key] != null && (
                        <div key={reportKey.key} style={{marginBottom: "15px"}}>
                          <Typography className={classes.body}>{ reportKey.name }</Typography>
                          <Button onClick={() => handleDownload(program[reportKey.key])}>Muat Turun</Button>
                        </div>
                      )
                    )
                  }
                </>
              ) : (
                <Typography className={classes.body}>Tiada Laporan</Typography>
              )
            }
          </>
        )}
      </div>
    </div>
  )
}

export default Queries;