import React, { useContext } from "react";
import { CircularProgress } from "@material-ui/core";
import { MapContext } from "../contexts/MapContext";

const MapLoadingSpinner = () => {
  const { mapLoading } = useContext(MapContext);

  return (
    <div style={styles.loadingContainer}>
      { mapLoading && <CircularProgress color="primary" thickness={4}/> }
    </div>
  )
}

const styles = {
  loadingContainer: {
    width: "45px",
    height: "45px",
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: "90px",
    right: "10px",
  }
}

export default MapLoadingSpinner;