import React from "react";
import { Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import styles from "../styles";

const useStyles = makeStyles(styles);

const PublicRoute = ({ component: Component, render, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <Route
          {...rest}
          render={render ? render : (props) => <Component {...props} />}
        />
      </div>
    </div>
  )
};

export default PublicRoute;
