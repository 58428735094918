import React from "react";
import { makeStyles } from "@material-ui/core";
import { convert3857to4326 } from "src/utils/coordinatesConverter";
import findLayerType from "src/utils/findLayerType";
import styles from "src/styles";

const useStyles = makeStyles(styles);

const Diff = ({ diff, layerTypes }) => {
  const classes = useStyles();

  const getLayerTypeName = (id) => {
    const item = findLayerType(id, layerTypes, 'id');
    return item?.name || 'undefined';
  }

  const renderAttributeName = (name) => {
    switch (name) {
      case ("productive"):
        return "Berhasil";
      case ("mature"):
        return "Matang";
      case ("hasil"):
        return "Berat Bersih Hasil Sawit (Tan)";
      case ("unhealthy_label"):
        return "Label";
      case ("vacant_point_label"):
        return "Label";
      default:
        return name;
    }
  }

  return (
    Object.entries(diff).map(([k,v]) => (
      k == "attribute_values" ? (
        Object.entries(v[1]).map(([i,j]) => (
          j != v[0][i] && (
            <div className={classes.diffRow}>
              <span>{ renderAttributeName(i) }</span>
              <div style={{display: "flex", flexDirection: "row"}}>
              {
                v[0][i] != null && (
                  <del className={classes.negativeDelta}>{ typeof v[0][i] == "boolean" ? v[0][i] == true ? "TRUE" : "FALSE" : v[0][i]  }</del>
                )
              }
              {
                j != null && (
                  <span className={classes.positiveDelta}>{ typeof j == "boolean" ? j == true ? "TRUE" : "FALSE" : j }</span>
                )
              }
              </div>
            </div>
          )
        ))
      ) : (
        k != "entity_id" && (
          k == "images" ? (
            <div className={classes.diffRow}>
              <span>Gambar-gambar</span>
              <div style={{display: "block"}}>
                {
                  v['added'].map((image) => (
                    <div>
                      <div style={{display: "inline-block", padding: "2px 4px", backgroundColor: "#DAF9DB", marginBottom: "5px"}}>
                        <img key={image.id} src={image.url} style={{maxHeight: "100px", objectFit: "cover"}} />
                        <p style={{marginBottom: 0}}>Gambar ditambah</p>
                      </div>
                    </div>
                  ))
                }
                {
                  v['deleted'].map((image) => (
                    <div>
                      <div style={{display: "inline-block", padding: "2px 4px", backgroundColor: "#FEDFDB", marginBottom: "5px"}}>
                        <img key={image.id} src={image.url} style={{maxHeight: "100px", objectFit: "cover"}} />
                        <p style={{marginBottom: 0}}>Gambar dibuang</p>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          ) : (
            <div className={classes.diffRow}>
              <span>{ k == "layer_type_id" ? "Jenis" : k == "name" ? "Tajuk" : k == "geometry" ? "Geometri" : k }</span>
              <div style={{display: "flex", flexDirection: "row"}}>
                {
                  v[0] != null && (
                    <del className={classes.negativeDelta}>{ k == "layer_type_id" ? getLayerTypeName(v[0]) : k == "geometry" ? convert3857to4326(v[0]) : v[0] }</del>
                  )
                }
                {
                  v[1] != null && (
                    <span className={classes.positiveDelta}>{ k == "layer_type_id" ? getLayerTypeName(v[1]) : k == "geometry" ? convert3857to4326(v[1]) : v[1] }</span>
                  )
                }
              </div>
            </div>
          )
        )
      )
    ))
  )
}

export default Diff;