const createKML = (coordinates, type = "LineString") => {
  let finalXml = '';
  let xmlHead = '<?xml version="1.0" encoding="UTF-8"?> <kml xmlns="http://www.opengis.net/kml/2.2"> <Document> <Placemark> ';
  let xmlTail = ' </Placemark> </Document> </kml>';

  finalXml = finalXml + xmlHead;

  if (type == "LineString") {
    finalXml = finalXml + ' <LineString> <coordinates> ';
    coordinates.forEach((coordinate) => {
      finalXml = finalXml + ` ${coordinate[0]},${coordinate[1]},0 `
    })
    finalXml = finalXml + ' </coordinates> </LineString> <Style> <LineStyle> <color>#cc0080ff</color> <width>3</width> </LineStyle> </Style> ';
  } else if (type == "Polygon") {
    finalXml = finalXml + ' <Polygon> <outerBoundaryIs> <LinearRing> <coordinates> ';
    coordinates[0].forEach((coordinate) => {
      finalXml = finalXml + ` ${coordinate[0]},${coordinate[1]},0 `
    })
    finalXml = finalXml + ' </coordinates> </LinearRing> </outerBoundaryIs> </Polygon> <Style> <PolyStyle> <color>#cc0080ff</color> <outline>0</outline> </PolyStyle> </Style> ';
  }
  finalXml = finalXml + xmlTail;
  return finalXml;
}

export default createKML;