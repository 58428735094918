import React, { createContext, useContext, useState } from "react";
import Axios from "axios";
import { API_ROOT } from "../Config";
import { AuthContext } from "./AuthContext";
import { ProgramContext } from "./ProgramContext";

const BlockContext = createContext();

const BlockProvider = ({ children }) => {
  const { userToken } = useContext(AuthContext);
  const [blocksTotalPages, setBlocksTotalPages] = useState(1);
  const [blocksTotalCount, setBlocksTotalCount] = useState(1);
  const [blocks, setBlocks] = useState([]);
  const [block, setBlock] = useState(null);

  const getBlocks = async (type, page = 1, filter = null, keyword = null, callback = null) => {
    let url = `${API_ROOT}/layer_types/${type}/layers?page=${page}`
    if (filter == 'good') {
      url = url + '&sph[gt]=130'
    } else if (filter == 'medium') {
      url = url + '&sph[gt]=100&sph[lt]=129'
    } else if (filter == 'bad') {
      url = url + '&sph[lt]=99'
    }

    if (keyword != null && keyword != "") {
      url = url + `&name[eq]=${keyword}`;
    }
    setBlocks([]);
    await Axios.get(url, {
      headers: { 'Authorization': 'Bearer ' + userToken }
    })
    .then((response) => {
      const paginationHeader = JSON.parse(response.headers['x-pagination']);
      setBlocksTotalCount(paginationHeader.total_count);
      setBlocksTotalPages(paginationHeader.total_pages);
      setBlocks(response.data.data);
      if (callback != null) {
        callback(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const getBlock = async (id, callback = null) => {
    await Axios.get(`${API_ROOT}/layers/${id}`, {
      headers: { 'Authorization': 'Bearer ' + userToken }
    })
    .then((response) => {
      setBlock(response.data.data);
      if (callback != null) {
        callback(false);
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }

  return (
    <BlockContext.Provider
      value={{
        block,
        blocks,
        blocksTotalCount,
        blocksTotalPages,
        getBlocks,
        getBlock
      }}
    >
      { children }
    </BlockContext.Provider>
  )
}

export { BlockContext, BlockProvider };