const kebabize = (str) => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase())

const camelCaseToKebabCase = (oldObject) => {
  var newObject = {}

  for (var camel in oldObject) {
    newObject[kebabize(camel)] = oldObject[camel];
  }

  return newObject;
}

export default camelCaseToKebabCase;