import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Buffer } from 'buffer';
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import Overview from "./pages/Overview";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import LocationSelect from "./pages/LocationSelect";
import Lots from "./pages/Lots";
import Lot from "./pages/Lots/show";
import Levels from "./pages/Levels";
import Level from "./pages/Levels/show";
import Blocks from "./pages/Blocks";
import Block from "./pages/Blocks/show";
import Resolutions from "./pages/Resolution";
import Reports from "./pages/Queries";
import Account from "./pages/Account";
global.Buffer = Buffer;

const App = () => {
  return (
    <Router>
      <Switch>
        <PublicRoute
          exact
          path="/"
          render={() => {
            window.location.pathname = "/login";
          }}
        />
        <PublicRoute path="/login" component={Login}/>
        <PublicRoute path="/forgot_password" component={ForgotPassword}/>
        <PublicRoute path="/reset_password" component={ResetPassword}/>
        <PrivateRoute exact path="/programs/select" component={LocationSelect} />
        <PrivateRoute path="/programs/:program_id/resolutions" component={Resolutions} withNavbar />
        <PrivateRoute path="/programs/:program_id/reports" component={Reports} withNavbar />
        <PrivateRoute path="/programs/:program_id/account" component={Account} withNavbar />
        <PrivateRoute path="/programs/:program_id/levels/:id" component={Level} withNavbar withMap/>
        <PrivateRoute path="/programs/:id/levels" component={Levels} withNavbar withMap/>
        <PrivateRoute path="/programs/:program_id/blocks/:id" component={Block} withNavbar withMap/>
        <PrivateRoute path="/programs/:id/blocks" component={Blocks} withNavbar withMap/>
        <PrivateRoute path="/programs/:program_id/lots/:lot_layer_name/:id" component={Lot} withNavbar withMap/>
        <PrivateRoute path="/programs/:id/lots/:lot_layer_name" component={Lots} withNavbar withMap/>
        <PrivateRoute path="/programs/:id" component={Overview} withNavbar withMap/>
      </Switch>
    </Router>
  )
}

export default App;
