import React, { createContext, useContext, useState } from "react";
import Axios from "axios";
import { API_ROOT } from "../Config";
import { AuthContext } from "./AuthContext";

const LayerTypeContext = createContext();

const LayerTypeProvider = ({ children }) => {
  const { userToken } = useContext(AuthContext);
  const [layerTypes, setLayerTypes] = useState([]);

  const getLayerTypes = async (entityId, callback = null) => {
    await Axios.get(`${API_ROOT}/maps/${entityId}/layer_types`, {
      headers: { 'Authorization': 'Bearer ' + userToken }
    })
    .then((response) => {
      setLayerTypes(response.data.data);
      if (callback != null) {
        callback(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }

  return (
    <LayerTypeContext.Provider
      value={{
        layerTypes,
        getLayerTypes
      }}
    >
      { children }
    </LayerTypeContext.Provider>
  )
}

export { LayerTypeContext, LayerTypeProvider };
