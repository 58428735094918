import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { BsChevronLeft } from 'react-icons/bs';
import Lottie from 'react-lottie';
import styles from '../../styles';
import { MapContext } from '../../contexts/MapContext';
import SphCard from '../../components/SphCard';
import InfoTable from '../../components/InfoTable';
import loadingAnimation from '../../assets/images/loading.json';
import { BlockContext } from '../../contexts/BlockContext';
import numberWithCommas from 'src/utils/numberWithCommas';
import currentMonthString from 'src/utils/currentMonthString';

const useStyles = makeStyles(styles);

const Block = (props) => {
  const classes = useStyles();
  const { program_id, id } = useParams();
  const { block, getBlock } = useContext(BlockContext);
  const { getBlockTiles } = useContext(MapContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getBlock(id, setLoading);
  }, []);

  useEffect(() => {
    if (!loading) {
      getBlockTiles(program_id, id);
    }
  }, [loading]);

  return (
    <>
      <Helmet>
        <title>Block</title>
      </Helmet>

      <div style={{ paddingLeft: '40px', paddingRight: '40px', paddingTop: '20px' }}>
        <IconButton onClick={() => props.history.goBack()} style={{ marginLeft: '-12px' }}>
          <BsChevronLeft size={'20px'} color={'#FA9961'} />
        </IconButton>
        {!loading ? (
          <>
            <div className={classes.searchResult} style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography className={classes.heading}>{block.name}</Typography>
              </div>

              <SphCard sph={block.sph} />
            </div>

            <Typography className={classes.subheading}>Statistik</Typography>

            <InfoTable style={{ marginBottom: '20px' }}>
              <InfoTable.Row>
                <InfoTable.Col
                  title={'Jumlah Keluasan Ladang Sawit'}
                  data={numberWithCommas(parseFloat(block.total_area).toFixed(2))}
                  unit={'hektar'}
                />
                <InfoTable.Col
                  title={'Keluasan Boleh Tanam'}
                  data={numberWithCommas(parseFloat(block.plantable_area).toFixed(2))}
                  unit={'hektar'}
                />
              </InfoTable.Row>

              <InfoTable.Row>
                <InfoTable.Col title={'Dirian Pokok'} data={numberWithCommas(block.tree_count)} />
                <InfoTable.Col title={'Purata SPH'} data={block.sph} />
              </InfoTable.Row>

              <InfoTable.Row>
                <InfoTable.Col title={'Dirian Kosong'} data={numberWithCommas(block.vacant_point_count)} />
                <InfoTable.Col title={'Pokok Tidak Sihat'} data={numberWithCommas(block.unhealthy_count)} />
              </InfoTable.Row>

              <InfoTable.Row>
                <InfoTable.Col
                  title={`Berat Bersih Bulan ${currentMonthString()}`}
                  data={numberWithCommas(block.berat_bersih_bulan_terkini) || 'N/A'}
                  unit={'tan'}
                />
                <InfoTable.Col
                  title={'Keluasan Hasil'}
                  data={numberWithCommas(block.keluasan_hasil) || 'N/A'}
                  unit={'hektar'}
                />
              </InfoTable.Row>

              <InfoTable.Row>
                <InfoTable.Col
                  title={`Berat Bersih Per Hektar Bulan ${currentMonthString()}`}
                  data={numberWithCommas(block.berat_bersih_per_hektar_bulan_terkini) || 'N/A'}
                  unit={'tan/ha'}
                />
              </InfoTable.Row>
            </InfoTable>
          </>
        ) : (
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: loadingAnimation,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={200}
            width={200}
          />
        )}
      </div>
    </>
  );
};

export default Block;
