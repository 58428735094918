import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";

const styles = {
  activeChip: {
    cursor: "pointer",
    padding: "5px 10px 5px 10px",
    border: "2px solid #FA9961",
    backgroundColor: "#FA9961",
    borderRadius: "10px",
    '&:hover': {
      backgroundColor: "#FFBE99",
      border: "2px solid #FFBE99"
    }
  },
  inactiveChip: {
    cursor: "pointer",
    padding: "5px 10px 5px 10px",
    border: "2px solid #FA9961",
    backgroundColor: "transparent",
    borderRadius: "10px",
    '&:hover': {
      backgroundColor: "#F0F1F5",
    }
  },
  activeText: {
    color: "#FFFFFF"
  },
  inactiveText: {
    color: "#FA9961"
  }
}

const useStyles = makeStyles(styles);

const Chip = ({ label, active, callback, style, onClick }) => {
  const classes = useStyles();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(active === true);
  }, [])

  useEffect(() => {
    setIsActive(active === true);
  }, [active])

  const toggleChip = () => {
    setIsActive(!isActive);
    if (callback != null) {
      callback();
    }
  }

  return (
    <div className={isActive ? classes.activeChip : classes.inactiveChip} style={style} onClick={onClick == null ? toggleChip : onClick}>
      <Typography className={isActive ? classes.activeText : classes.inactiveText}>{label}</Typography>
    </div>
  )
}

export default Chip;