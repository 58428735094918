import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, makeStyles, MenuItem, Select, TextareaAutosize, Typography } from "@material-ui/core";
import React, { useContext, useState, useCallback } from "react";
import { IoClose, IoChevronDownOutline } from "react-icons/io5";
import Modal from '@material-ui/core/Modal';
import styles from "../styles";
import { LayerContext } from "src/contexts/LayerContext";
import { LayerTypeContext } from "src/contexts/LayerTypeContext";
import useWindowSize from 'src/utils/useWindowSize';
import dateFormat from "src/utils/dateFormat";
import Diff from "src/components/Diff";
import FilesDropzone from './FilesDropzone';
import { useImage } from "react-image";
import { Skeleton } from "@material-ui/lab";
import ImageViewer from 'react-simple-image-viewer';

const useStyles = makeStyles(styles);

const unhealthyLabels = [
  "Ulat Bungkus (Sesuai rawatan)",
  "Ulat Bungkus (Tidak sesuai rawatan)",
  "Ganoderma (Kategori 1)",
  "Ganoderma (Kategori 2)",
  "Ganoderma (Kategori 3)",
  "Kumbang Badak",
  "Kekurangan Nutrien"
]

const vacantPointLabels = [
  "Ganoderma",
  "Serangan gajah",
  "Banjir",
  "Kilat",
  "Kebakaran",
  "Tidak bertanam"
]

function getModalStyle(width, height) {
  return {
    position: 'absolute',
    top: 25,
    left: width > 1000 ? (width - 1000) / 2 : 25,
    minHeight: 700,
    minWidth: 500,
    maxWidth: 1000,
    width: 'calc(100vw - 50px)',
    height: 'calc(100vh - 50px)',
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    outline: 'none'
  };
}

const Image = ({ url, index, onClick }) => {
  const { src, isLoading } = useImage({
    srcList: url,
    useSuspense: false
  })

  return isLoading ? <Skeleton variant="rectangular" width="180px" height="180px" style={{marginRight: "10px", marginBottom: "10px"}}/> : <img key={index} src={src} style={{width: "180px", height: "180px", objectFit: "cover", marginRight: "10px", marginBottom: "10px", cursor: "pointer"}} onClick={onClick}/>
}

const IssueModal = (props) => {
  const {
    setSelectedIssue,
    selectedIssue,
    selectableLayerTypes,
    modalOpen,
    setModalOpen
  } = props;
  const { layerTypes } = useContext(LayerTypeContext);
  const { updateLayer, getLayer } = useContext(LayerContext);
  const [issue, setIssue] = useState({...selectedIssue})
  const size = useWindowSize();
  const classes = useStyles();
  const [selectedIssueLayerTypeId, setSelectedIssueLayerTypeId] = useState(issue.layer_type.id);
  const [selectedIssueLabel, setSelectedIssueLabel] = useState((issue.layer_type.key == "unhealthy_point" || issue.layer_type.key == "unhealthy_point_in_progress") ? issue.unhealthy_label == null ? "none" : issue.unhealthy_label : issue.layer_type.key == "vacant_point" ? issue.vacant_point_label == null ? "none" : issue.vacant_point_label : "none");
  const [toggleAddNotes, setToggleAddNotes] = useState(false);
  const [notes, setNotes] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [toggleSuccessMessage, setToggleSuccessMessage] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [showImageViewer, setShowImageViewer] = useState(false);
  const disableNext = issue.layer_type.id === selectedIssueLayerTypeId && (issue.unhealthy_label === selectedIssueLabel || issue.vacant_point_label === selectedIssueLabel || (selectedIssueLabel === "none" && issue.unhealthy_label === null) || (selectedIssueLabel === "none" && issue.vacant_point_label === null)) && uploadedImages.length == 0;

  const handleCloseModal = () => {
    setSelectedIssue(null);
    setModalOpen(false);
  }

  const getStatus = (status) => {
    if (status === 'pending') {
      return 'Belum diluluskan'
    } else if (status === 'approved') {
      return 'Diluluskan'
    } else if (status === 'rejected') {
      return 'Ditolak'
    }
    return 'undefined'
  }

  const handleShowSuccessMessage = () => {
    setToggleSuccessMessage(true);
    setTimeout(function(){ setToggleSuccessMessage(false); }, 3000);
  }

  const handleResolve = (selectedIssueLayerTypeId, notes) => {
    const updateFormData = new FormData();

    updateFormData.append(`[layer]layer_type_id`, selectedIssueLayerTypeId);
    updateFormData.append(`[layer]notes`, notes);

    if (selectedIssueLayerTypeId == selectableLayerTypes.find((item) => item.key == "unhealthy_point").id) {
      if (selectedIssueLabel == "none") {
        updateFormData.append(`[layer]unhealthy_label`, '');
      } else {
        updateFormData.append(`[layer]unhealthy_label`, selectedIssueLabel);
      }
    } else if (selectedIssueLayerTypeId == selectableLayerTypes.find((item) => item.key == "unhealthy_point_in_progress").id) {
      if (selectedIssueLabel == "none") {
        updateFormData.append(`[layer]unhealthy_label`, '');
      } else {
        updateFormData.append(`[layer]unhealthy_label`, selectedIssueLabel);
      }
    } else if (selectedIssueLayerTypeId == selectableLayerTypes.find((item) => item.key == "vacant_point").id) {
      if (selectedIssueLabel == "none") {
        updateFormData.append(`[layer]vacant_point_label`, '');
      } else {
        updateFormData.append(`[layer]vacant_point_label`, selectedIssueLabel);
      }
    }

    if (uploadedImages.length > 0) {
      for (var uploadedImage of uploadedImages) {
        updateFormData.append(`[layer]images[]`, uploadedImage);
      }
    }

    updateLayer(issue.id, updateFormData, (res) => {
      getLayer(
        issue.id,
        (point) => {
          setIssue(point);
          setNotes(null);
          setSelectedIssueLayerTypeId(point.layer_type.id);
          setToggleAddNotes(false);
          setUploadedImages([]);
          handleShowSuccessMessage();
        },
        true
      )
    });
  }

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setShowImageViewer(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setShowImageViewer(false);
  };

  return (
    <Modal
       open={modalOpen}
       onClose={handleCloseModal}
     >
       <div style={getModalStyle(size.width, size.height)}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={handleCloseModal} style={{ marginRight: 10 }}>
                <IoClose size={"25px"} color={"#FA9961"}/>
              </IconButton>
              <Typography className={classes.subheading}>Lapisan {issue.id}</Typography>
            </div>
            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
              {
                toggleSuccessMessage &&
                <Typography
                  className={classes.body}
                  style={{ color: 'green', marginRight: 10 }}
                >
                  Perubahan berjaya dihantar.
                </Typography>
              }
              <Button
                disableRipple
                style={{
                  backgroundColor: (disableNext || toggleAddNotes) && 'transparent',
                  color: disableNext ? 'lightgrey' : (toggleAddNotes && 'black')
                }}
                onClick={() => setToggleAddNotes(!toggleAddNotes)}
                disabled={disableNext}
              >
                {toggleAddNotes ? 'Kembali' : 'Seterusnya'}
              </Button>
              {
                toggleAddNotes &&
                <Button
                  onClick={() => handleResolve(selectedIssueLayerTypeId, notes)}
                >
                  Hantar
                </Button>
              }
            </div>
          </div>
          {
            toggleAddNotes ?
            <div style={{ display: 'flex', flexDirection: 'column', gap: 20, margin: '0 20px 0 20px' }}>
              <div>
                <Typography className={classes.body} style={{ width: 100 }}>Nota</Typography>
                <TextareaAutosize
                  className={classes.textInput}
                  value={notes}
                  onChange={(val) => {setNotes(val.target.value)}}
                  minRows={5}
                  style={{ width: '100%', marginTop: 10, border: 0 }}
                />
              </div>
            </div> :
            <div style={{ display: 'flex', flexDirection: 'column', gap: 20, margin: '0 20px 0 20px', overflowY: "scroll" }}>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography className={classes.body} style={{ width: 100 }}>Koordinat</Typography>
                <span>
                  {`${parseFloat(selectedIssue.longitude).toFixed(8)},
                  ${parseFloat(selectedIssue.latitude).toFixed(8)}`}
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <Typography className={classes.body} style={{ width: 100 }}>Jenis</Typography>
                  <Select
                    displayEmpty
                    disableUnderline
                    className={classes.textInput}
                    value={selectedIssueLayerTypeId}
                    onChange={(e) => { setSelectedIssueLayerTypeId(e.target.value); setSelectedIssueLabel("none"); }}
                  >
                    <MenuItem value="" disabled>
                      Pilih
                    </MenuItem>
                    {
                      selectableLayerTypes.map((selectableLayerType, index) => (
                        <MenuItem
                          key={index}
                          value={selectableLayerType.id}
                        >
                          {selectableLayerType.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
              </div>
              { (selectedIssueLayerTypeId == selectableLayerTypes.find((item) => item.key == "unhealthy_point").id || selectedIssueLayerTypeId == selectableLayerTypes.find((item) => item.key == "unhealthy_point_in_progress").id) && (
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <Typography className={classes.body} style={{ width: 100 }}>Label</Typography>
                  <Select
                    displayEmpty
                    disableUnderline
                    className={classes.textInput}
                    value={selectedIssueLabel}
                    onChange={(e) => setSelectedIssueLabel(e.target.value)}
                  >
                    <MenuItem value="" disabled>
                      Pilih
                    </MenuItem>
                    <MenuItem value="none">
                      Tiada
                    </MenuItem>
                    {
                      unhealthyLabels.map((unhealthyLabel, index) => (
                        <MenuItem key={index} value={unhealthyLabel}>
                          { unhealthyLabel }
                        </MenuItem>
                      ))
                    }
                  </Select>
                </div>
              )}
              { selectedIssueLayerTypeId == selectableLayerTypes.find((item) => item.key == "vacant_point").id && (
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <Typography className={classes.body} style={{ width: 100 }}>Label</Typography>
                  <Select
                    displayEmpty
                    disableUnderline
                    className={classes.textInput}
                    value={selectedIssueLabel}
                    onChange={(e) => setSelectedIssueLabel(e.target.value)}
                  >
                    <MenuItem value="" disabled>
                      Pilih
                    </MenuItem>
                    <MenuItem value="none">
                      Tiada
                    </MenuItem>
                    {
                      vacantPointLabels.map((vacantPointLabel, index) => (
                        <MenuItem key={index} value={vacantPointLabel}>
                          { vacantPointLabel }
                        </MenuItem>
                      ))
                    }
                  </Select>
                </div>
              )}
              <div>
                <Typography className={classes.body} style={{ marginBottom: 15 }}>Gambar-gambar</Typography>
                <div style={{marginBottom: "10px"}}>
                  {
                    selectedIssue.images.map((image, index) => (
                      <Image key={index} url={image.url} onClick={() => openImageViewer(index)}/>
                    ))
                  }
                </div>
                <FilesDropzone
                  onChangeFiles={setUploadedImages}
                  accept='image/*'
                />
              </div>                
                <Accordion
                  elevation={0}
                  classes={{ root: classes.MuiAccordionroot }}
                >
                  <AccordionSummary
                    expandIcon={<IoChevronDownOutline size={"25px"}/>}
                    classes={{ root: classes.MuiAccordionSummaryRoot }}
                  >
                    <Typography className={classes.body} style={{ width: 200 }}>Perubahan lalu</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    classes={{ root: classes.MuiAccordionDetailsRoot }}
                  >
                  {
                    issue.changes.length > 0 ?
                      <table className="changesTable">
                        <tr style={{ textAlign: 'left' }}>
                          <th>Tarikh</th>
                          <th>Perubahan</th>
                          <th>Nota</th>
                          <th>Status</th>
                        </tr>
                        {
                          issue.changes.map((item, index) => (
                            <tr style={{borderBottom: index == issue.changes.length - 1 ? null : "1px solid grey"}}>
                              <td>{ dateFormat((new Date(item.created_at)), "d/m/yyyy") }</td>
                              <td>
                                <Diff diff={item.diff} layerTypes={layerTypes}/>
                              </td>
                              <td>{item.notes || '-'}</td>
                              <td>{getStatus(item.state)}</td>
                            </tr>
                          ))
                        }
                      </table> :
                      <span>Tiada</span>
                  }
                  </AccordionDetails>
                </Accordion>
            </div>
          }
          { showImageViewer && (
            <ImageViewer
              src={selectedIssue.images.map((image) => image.url)}
              currentIndex={ currentImage }
              disableScroll={ false }
              closeOnClickOutside={ true }
              onClose={ closeImageViewer }
            />
          )}
       </div>
       
     </Modal>
  )
}

export default IssueModal;
