import React, { createContext, useContext, useState } from "react";
import Axios from "axios";
import { API_ROOT } from "../Config";
import { AuthContext } from "./AuthContext";
import { ProgramContext } from "./ProgramContext";

const LevelContext = createContext();

const LevelProvider = ({ children }) => {
  const { userToken } = useContext(AuthContext);
  const [levelsTotalPages, setLevelsTotalPages] = useState(1);
  const [levelsTotalCount, setLevelsTotalCount] = useState(1);
  const [levels, setLevels] = useState([]);
  const [level, setLevel] = useState(null);

  const getLevels = async (type, page = 1, filter = null, keyword = null, callback = null) => {
    let url = `${API_ROOT}/layer_types/${type}/layers?page=${page}`
    if (filter == 'good') {
      url = url + '&sph[gt]=130'
    } else if (filter == 'medium') {
      url = url + '&sph[gt]=100&sph[lt]=129'
    } else if (filter == 'bad') {
      url = url + '&sph[lt]=99'
    }

    if (keyword != null && keyword != "") {
      url = url + `&name[eq]=${keyword}`;
    }
    setLevels([]);
    await Axios.get(url, {
      headers: { 'Authorization': 'Bearer ' + userToken }
    })
    .then((response) => {
      const paginationHeader = JSON.parse(response.headers['x-pagination']);
      setLevelsTotalCount(paginationHeader.total_count);
      setLevelsTotalPages(paginationHeader.total_pages);
      setLevels(response.data.data);
      if (callback != null) {
        callback(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const getLevel = async (id, callback = null) => {
    await Axios.get(`${API_ROOT}/layers/${id}`, {
      headers: { 'Authorization': 'Bearer ' + userToken }
    })
    .then((response) => {
      setLevel(response.data.data);
      if (callback != null) {
        callback(false);
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }

  return (
    <LevelContext.Provider
      value={{
        level,
        levels,
        levelsTotalCount,
        levelsTotalPages,
        getLevels,
        getLevel
      }}
    >
      { children }
    </LevelContext.Provider>
  )
}

export { LevelContext, LevelProvider };