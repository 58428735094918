import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import styles from "../styles";

const useStyles = makeStyles(styles);

const InfoTable = ({ children, style }) => {
  const classes = useStyles();

  return (
    <div className={classes.infoTable} style={style}>
      { children }
    </div>
  )
}

const InfoTableRow = ({ first, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.infoTableRow} style={{borderTop: first ? "3px solid #F0F1F5" : null}}>
      { children }
    </div>
  )
}

const InfoTableColumn = ({ title, data, unit }) => {
  const classes = useStyles();

  return (
    <div className={classes.infoTableColumn}>
      <Typography className={classes.tinyHeading}>{title}</Typography>
      <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
        <Typography className={classes.info}>{data}</Typography>
        { unit != null && <Typography className={classes.body} style={{marginLeft: "10px"}}>{unit}</Typography> }
      </div>
    </div>
  )
}

InfoTable.Row = InfoTableRow;
InfoTable.Col = InfoTableColumn;

export default InfoTable;