import React, { useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { AiFillEye } from "react-icons/ai";
import { Chip } from "react-awesome-chip";
import { AuthContext } from "../../contexts/AuthContext";
import styles from "../../styles";
import dateFormat from "../../utils/dateFormat";
import { ChangeContext } from "src/contexts/ChangeContext";
import { LayerTypeContext } from "src/contexts/LayerTypeContext";
import { LayerContext } from "src/contexts/LayerContext";
import Diff from "src/components/Diff";

const useStyles = makeStyles(styles);

const Resolutions = (props) => {
  const classes = useStyles();
  const { program_id } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const page = parseInt(query.get("page")) || 1;
  const { changes, changesTotalPages, getChanges, approveChange, rejectChange } = useContext(ChangeContext);
  const { layerTypes } = useContext(LayerTypeContext);
  const { getLayer } = useContext(LayerContext)
  const { user } = useContext(AuthContext);

  useEffect(() => {
    getChanges(program_id, page);
  }, [page])

  const handleChangePage = (event, value) => {
    let url = `/programs/${program_id}/resolutions?page=${value}`;

    props.history.push(url);
  }

  const handleApprove = (id) => {
    approveChange(id);
  }

  const handleReject = (id) => {
    rejectChange(id);
  }

  const renderActionTag = (str) => {
    switch (str) {
      case ("create"):
        return (
          <Chip title="CIPTA" color="#38C979" />
        )
      case ("update"):
        return (
          <Chip title="UBAH" color="#FFBE51" />
        )
      case ("delete"):
        return (
          <Chip title="BUANG" color="#FF5677" />
        )
    }
  }

  const navigateToPointParent = (id) => {
    getLayer(id, (layer) => {
      if (layer.parent != null) {
        var route = "lots/peneroka";
        if (layer.parent.layer_type.key == "level") {
          route = "levels";
        } else if (layer.parent.layer_type.key == "block") {
          route = "blocks"
        } else if (layer.parent.layer_type.key == "lot") {
          route = "lots/peneroka";
        } else if (layer.parent.layer_type.key == "felda_lot") {
          route = "lots/felda"
        }
        props.history.push(`/programs/${program_id}/${route}/${layer.parent.id}`);
      }
    });
  }

  return (
    <div className={classes.page}>
      <div style={{paddingLeft: "40px",paddingRight: "40px",paddingTop: "20px"}}>
        <Typography className={classes.heading} style={{marginBottom: "20px"}}>Aktiviti</Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography className={classes.subheading}>Tarikh</Typography>
              </TableCell>

              {
                user.permissions.some((permission) => permission.includes("map:ApproveLayerChanges")) && (
                  <TableCell align="left">
                    <Typography className={classes.subheading}>Pengguna</Typography>
                  </TableCell>
                )
              }

              <TableCell align="left">
                <Typography className={classes.subheading}>Lapisan</Typography>
              </TableCell>

              <TableCell>
                <Typography className={classes.subheading}>Tindakan</Typography>
              </TableCell>

              <TableCell>
                <Typography className={classes.subheading}>Perubahan</Typography>
              </TableCell>


              <TableCell>
                <Typography className={classes.subheading}>Nota</Typography>
              </TableCell>
              {
                user.permissions.some((permission) => permission.includes("map:ApproveLayerChanges")) ? (
                  <>
                    <TableCell align="center">
                      <Typography className={classes.subheading}></Typography>
                    </TableCell>
                  </>
                ) : (
                  <TableCell align="center">
                    <Typography className={classes.subheading}>Status</Typography>
                  </TableCell>
                )
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              changes.map((change) => (
                <TableRow key={change.id}>
                  <TableCell align="left" width={"120px"}>
                    <Typography className={classes.body}>
                      { dateFormat((new Date(change.created_at)), "d/m/yyyy") }
                    </Typography>
                  </TableCell>

                  {
                    user.permissions.some((permission) => permission.includes("map:ApproveLayerChanges")) && (
                      <TableCell align="left">
                        <Typography className={classes.body}>
                          { change.requested_by.name }
                        </Typography>
                      </TableCell>
                    )
                  }

                  <TableCell align="left" width={"180px"}>     
                    <Chip title={change.changeable.id.slice(0, 12) + "..."} color="#627D98" onClick={() => navigateToPointParent(change.changeable.id)}/>
                  </TableCell>

                  <TableCell align="left" width={"120px"}>
                    { renderActionTag(change.action) }
                  </TableCell>

                  <TableCell align="left">
                    <Diff diff={change.diff} layerTypes={layerTypes} />
                  </TableCell>
                  
                  <TableCell align="left" style={{maxWidth: "100px"}}>
                    <div className={classes.body} style={{whiteSpace: "pre-wrap"}}>
                      { change.notes }
                    </div>
                  </TableCell>
                  {
                    user.permissions.some((permission) => permission.includes("map:ApproveLayerChanges")) ? (
                      <>
                        <TableCell align="center" width={"300px"}>
                          {
                            change.state == "pending" ? (
                              <>
                                <Button style={{width: "100px", marginRight: "10px"}} onClick={() => handleApprove(change.id)}>Luluskan</Button>
                                <Button style={{width: "100px"}} onClick={() => handleReject(change.id)}>Tolak</Button>
                              </>
                            ) : (
                              <Typography className={classes.boyd}>{ change.state.charAt(0).toUpperCase() + change.state.slice(1) }</Typography>
                            )
                            
                          }
                          
                        </TableCell>
                      </>
                    ) : (
                      <TableCell align="center" width={"200px"}>
                        <Typography className={classes.boyd}>{ change.state.charAt(0).toUpperCase() + change.state.slice(1) }</Typography>
                      </TableCell>
                    )
                  }
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <div style={{marginTop: "20px", marginBottom: "20px", display: "flex", justifyContent: "center"}}>
          <Pagination page={page} count={changesTotalPages} onChange={handleChangePage}/>
        </div>
      </div>
    </div>
  )
}

export default Resolutions;