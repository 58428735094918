import { Typography, makeStyles } from "@material-ui/core";
import React from "react";
import styles from "../styles";

const useStyles = makeStyles(styles);

const SphCard = ({ sph }) => {
  const classes = useStyles();

  let color = "#EB725E";
  
  if (sph >= 100) {
    color = "#F9DB38";
  }

  if (sph >= 130) {
    color = "#7ED321";
  }

  return (
    <div className={classes.sphContainer} style={{backgroundColor: color}}>
      <Typography className={classes.sphTitle}>SPH</Typography>
      <Typography className={classes.sphText}>{ sph }</Typography>
    </div>
  )
}

export default SphCard;