import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles, Typography, Popper, Grow, Paper, ClickAwayListener, MenuList, TextField, MenuItem, IconButton, Divider, Select } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import styles from "../styles";
import FeldaLogo from "../assets/images/felda-logo-cropped.jpg";
import { ProgramContext } from "../contexts/ProgramContext";
import { AuthContext } from "../contexts/AuthContext";
import { MapContext } from "../contexts/MapContext";
import { isLatitude, isLongitude } from 'src/utils/validation';

const useStyles = makeStyles(styles);

const NavBar = (props) => {
  const classes = useStyles();
  const { program } = useContext(ProgramContext);
  const { user, logout } = useContext(AuthContext);
  const { setSearchedCoordinate } = useContext(MapContext);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState("peneroka");
  const [placeholder, setPlaceholder] = useState("No lot, nama, IC, semua (biar kosong)")
  const [searchError, setSearchError] = useState(null);
  const anchorRef = useRef(null);

  useEffect(() => {
    if (searchError) {
      setSearchError(null)
    }
  }, [searchType, searchTerm]);

  useEffect(() => {
    if (searchType == "peneroka" || searchType == "felda") {
      setPlaceholder("No lot, nama, IC, semua (biar kosong)")
    } else if (searchType == "blocks") {
      setPlaceholder("No blok, semua (biar kosong)")
    } else if (searchType == "levels") {
      setPlaceholder("No peringkat, semua (biar kosong)")
    } else if (searchType == "coordinates") {
      setPlaceholder("Longitud, latitud")
    }
  }, [searchType])

  const handleGoToOverview = () => {
    setOpen(false);
    setSearchType("peneroka");
    setSearchTerm("");
    props.history.push(`/programs/${program.id}`)
  }

  const handleGoToResolutions = () => {
    setOpen(false);
    props.history.push(`/programs/${program.id}/resolutions`);
  }

  const handleGoToAccount = () => {
    setOpen(false);
    props.history.push(`/programs/${program.id}/account`);
  }

  const handleGoToReports = () => {
    setOpen(false);
    props.history.push(`/programs/${program.id}/reports`);
  }

  const handleGoToFertilizerOrder = (url) => {
    window.open(url, "_blank")
  }

  const handleGoToDashboard = () => {
    window.open("https://spms.felda.net.my/apps/c694aa4a-d29d-4380-a8ec-b4aa08aa1fc4", "_blank")
  }

  const handleSearchCoordinate = () => {
    const coordinates = searchTerm.split(",");
    const lon = Number(coordinates[0]);
    const lat = Number(coordinates[1]);
    if (isLatitude(lat) && isLongitude(lon)) {
      setSearchedCoordinate([lon, lat]);
    } else {
      setSearchError("Incorrect latitude/longitude format")
    }
  }

  const handleSearch = () => {
    if (searchType === 'coordinates') {
      handleSearchCoordinate()
    } else if (searchType === 'peneroka' || searchType === 'felda') {
      let url = `/programs/${program.id}/lots/${searchType}`;

      if (searchTerm !== "") {
        url = url + `?keyword=${searchTerm}`;
      }
      props.history.push(url);
    } else {
      let url = `/programs/${program.id}/${searchType}`;

      if (searchTerm != "") {
        url = url + `?keyword=${searchTerm}`;
      }
      props.history.push(url);
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleChangeProgram = () => {
    props.history.push("/programs/select");
  }

  const handleLogout = () => {
    logout();
  }

  return (
    <div className={classes.whiteBar} style={{marginTop: "-5rem"}}>
      <img
        src={FeldaLogo}
        alt="logo"
        className={classes.navLogo}
        onClick={handleGoToOverview}
      />
      <Paper component="form" className={classes.searchBox}>
        <Select
          disableUnderline
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
        >
          <MenuItem value="levels">Peringkat</MenuItem>
          <MenuItem value="blocks">Blok</MenuItem>
          <MenuItem value="peneroka">Lot Peneroka</MenuItem>
          <MenuItem value="felda">Lot Felda</MenuItem>
          <MenuItem value="coordinates">Koordinat</MenuItem>
        </Select>
        <Divider style={{height: 28, margin: 4}} orientation="vertical" />
        <TextField
          error={searchError}
          placeholder={placeholder}
          style={{marginLeft: "10px", flex: 1}}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          helperText={searchError}
          InputProps={{ disableUnderline: true }}
        />
        <IconButton onClick={handleSearch}>
          <BiSearch size={"20px"} color={"#FA9961"}/>
        </IconButton>
      </Paper>
      {
        program != null && user != null && (
          <div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}} ref={anchorRef}>
              <Typography className={classes.info}>{program.name}</Typography>
              <IconButton onClick={handleToggle} style={{ marginLeft: "10px" }}>
                <BsChevronDown size={"20px"} color={"#FA9961"} />
              </IconButton>
            </div>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              placement={"bottom-end"}
              style={{width: "200px", marginTop: "0.9rem"}}
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: 'right top' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open} id="menu-list-grow" style={{paddingBottom: 0, paddingTop: 0}}>
                        <div style={{ borderTop: '1px solid lightgrey', borderBottom: '1px solid lightgrey',  padding: 15  }}>
                          <Typography className={classes.bold}>{user.name}</Typography>
                          <Typography className={classes.tinyHeading}>{user.title}</Typography>
                        </div>
                        <MenuItem
                          style={{paddingBottom: "10px", paddingTop: "10px"}}
                          onClick={handleGoToAccount}
                        >
                          Tetapan Akaun
                        </MenuItem>
                        <MenuItem
                          style={{paddingBottom: "10px", paddingTop: "10px"}}
                          onClick={handleGoToResolutions}
                        >
                          Aktiviti
                        </MenuItem>
                        <MenuItem
                          style={{paddingBottom: "10px", paddingTop: "10px"}}
                          onClick={handleGoToReports}
                        >
                          Laporan
                        </MenuItem>
                        <MenuItem
                          style={{paddingBottom: "10px", paddingTop: "10px"}}
                          onClick={handleGoToDashboard}
                        >
                          Dashboard
                        </MenuItem>
                        {
                          program.fertilizer_order_url != null && (
                            <MenuItem
                              style={{paddingBottom: "10px", paddingTop: "10px"}}
                              onClick={() => handleGoToFertilizerOrder(program.fertilizer_order_url)}
                            >
                              Pesanan Baja
                            </MenuItem>
                          )
                        }
                        {
                          user.permissions.some((permission) => permission.includes("map:ListMaps")) && (
                            <MenuItem
                              style={{paddingBottom: "10px", paddingTop: "10px"}}
                              onClick={handleChangeProgram}
                            >
                              Pilihan Rancangan
                            </MenuItem>
                          )
                        }
                        <MenuItem
                          style={{paddingBottom: "10px", paddingTop: "10px"}}
                          onClick={handleLogout}
                        >
                          Log Keluar
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        )
      }
    </div>
  )
}

export default withRouter(NavBar);
