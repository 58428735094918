import proj4 from "proj4";
import Wkt from "wicket";
import { reproject } from "reproject";

const convert4326to3857 = (wktString) => {
  var wkt4326 = new Wkt.Wkt();
  wkt4326.read(wktString);
  const geojson4326 = {
    type: 'FeatureCollection',
    features: [
      {type: 'Feature', geometry: wkt4326.toJson()}
    ]
  };

  const geojson3857 = reproject(geojson4326, proj4.defs('EPSG:4326'), proj4.defs('EPSG:3857'));

  var wkt3857 = new Wkt.Wkt();
  wkt3857.read(JSON.stringify(geojson3857.features[0].geometry));
  return wkt3857.write()
}

const convert3857to4326 = (wktString) => {
  var wkt3857 = new Wkt.Wkt();
  wkt3857.read(wktString);
  const geojson3857 = {
    type: 'FeatureCollection',
    features: [
      {type: 'Feature', geometry: wkt3857.toJson()}
    ]
  };

  const geojson4326 = reproject(geojson3857, proj4.defs('EPSG:3857'), proj4.defs('EPSG:4326'));

  var wkt4326 = new Wkt.Wkt();
  wkt4326.read(JSON.stringify(geojson4326.features[0].geometry));
  return wkt4326.write()
}

export { convert3857to4326, convert4326to3857 }