import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { LinearProgress, makeStyles, Typography, withStyles } from '@material-ui/core';
import styles from '../styles';
import { ProgramContext } from '../contexts/ProgramContext';
import { MapContext } from '../contexts/MapContext';
import InfoTable from '../components/InfoTable';
import numberWithCommas from 'src/utils/numberWithCommas';
import currentMonthString from 'src/utils/currentMonthString';

const useStyles = makeStyles(styles);

const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 7,
  },
  colorPrimary: {
    backgroundColor: '#F0F1F5',
  },
  bar: {
    borderRadius: 7,
    backgroundColor: '#FA9961',
  },
}))(LinearProgress);

const Overview = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const { program, getProgram } = useContext(ProgramContext);
  const { getProgramTiles } = useContext(MapContext);

  useEffect(() => {
    if (id == null) {
      props.history.push('/programs/select');
      return;
    }

    getProgram(id);
    getProgramTiles(id);
  }, []);

  return (
    <>
      <Helmet>
        <title>Rumusan Rancangan</title>
      </Helmet>
      <div style={{ paddingLeft: '40px', paddingRight: '40px', paddingTop: '20px' }}>
        <div>
          <Typography className={classes.heading} style={{ marginBottom: '15px' }}>
            Rumusan Rancangan
          </Typography>
          {program != null && (
            <>
              <div className={classes.rowWithTwoColumns}>
                <Typography className={classes.bold}>Pokok Tidak Sihat</Typography>
                <Typography className={classes.body}>
                  {`${numberWithCommas(program.unhealthy_resolved_count)}/${numberWithCommas(
                    program.unhealthy_historical_count
                  )} selesai`}
                </Typography>
              </div>
              <CustomLinearProgress
                variant='determinate'
                style={{ margin: '10px 0 20px 0' }}
                value={(program.unhealthy_resolved_count / program.unhealthy_historical_count) * 100}
              />

              <div className={classes.rowWithTwoColumns}>
                <Typography className={classes.bold}>Dirian Kosong</Typography>
                <Typography className={classes.body}>
                  {`${numberWithCommas(program.vacant_point_resolved_count)}/${numberWithCommas(
                    program.vacant_point_historical_count
                  )} selesai`}
                </Typography>
              </div>
              <CustomLinearProgress
                variant='determinate'
                style={{ margin: '10px 0 20px 0' }}
                value={(program.vacant_point_resolved_count / program.vacant_point_historical_count) * 100}
              />

              <InfoTable>
                <InfoTable.Row>
                  <InfoTable.Col
                    title={'Jumlah Keluasan Ladang Sawit'}
                    data={numberWithCommas(parseFloat(program.total_area).toFixed(2))}
                    unit={'hektar'}
                  />
                  <InfoTable.Col
                    title={'Keluasan Boleh Tanam'}
                    data={numberWithCommas(parseFloat(program.plantable_area).toFixed(2))}
                    unit={'hektar'}
                  />
                </InfoTable.Row>

                <InfoTable.Row>
                  <InfoTable.Col title={'Dirian Pokok'} data={numberWithCommas(program.tree_count)} />
                  <InfoTable.Col title={'Purata SPH'} data={program.sph} />
                </InfoTable.Row>

                <InfoTable.Row>
                  <InfoTable.Col title={'Pokok Tidak Sihat'} data={numberWithCommas(program.unhealthy_count)} />
                  <InfoTable.Col title={'Dirian Kosong'} data={numberWithCommas(program.vacant_point_count)} />
                </InfoTable.Row>

                <InfoTable.Row>
                  <InfoTable.Col title={'Nama pengurus'} data={program.nama_pengurus || 'N/A'} />
                  <InfoTable.Col
                    title={'Bilangan Peneroka'}
                    data={numberWithCommas(program.bilangan_peneroka) || 'N/A'}
                  />
                </InfoTable.Row>

                <InfoTable.Row>
                  <InfoTable.Col
                    title={`Berat Bersih Bulan ${currentMonthString()}`}
                    data={numberWithCommas(program.berat_bersih_bulan_terkini) || 'N/A'}
                    unit={'tan'}
                  />
                  <InfoTable.Col
                    title={'Keluasan Hasil'}
                    data={numberWithCommas(program.keluasan_hasil) || 'N/A'}
                    unit={'hektar'}
                  />
                </InfoTable.Row>

                <InfoTable.Row>
                  <InfoTable.Col
                    title={`Berat Bersih Per Hektar Bulan ${currentMonthString()}`}
                    data={numberWithCommas(program.berat_bersih_per_hektar_bulan_terkini) || 'N/A'}
                    unit={'tan/ha'}
                  />
                </InfoTable.Row>
              </InfoTable>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Overview;
