function currentMonthString() {
  let date = new Date();
  date.setDate(date.getDate() - 15);

  if (date.getMonth() === 0) {
    return `12/${date.getFullYear() - 1}`;  
  } else {
    return `${date.getMonth()}/${date.getFullYear()}`;  
  }
}

export default currentMonthString;
