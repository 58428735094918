import React from "react";
import { Dialog, IconButton, makeStyles, Typography } from "@material-ui/core";
import { IoClose } from "react-icons/io5";
import styles from "../styles";

const useStyles = makeStyles(styles);

const Modal = ({ children, open, onClose, title }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
    >
      <div className={classes.modalContainer}>
        <div style={{display: "flex", flexDirection:"row"}}>
          <div style={{flex:1}}>
            <IconButton onClick={onClose} style={{marginLeft: "-12px"}}>
              <IoClose size={"30px"} color={"#FA9961"}/>
            </IconButton>
          </div>
          <div className={classes.modalTitle}>
            <Typography className={classes.info}>{ title }</Typography>
          </div>
          <div style={{flex:1}} />
        </div>
        { children }
      </div>
    </Dialog>
  )
}

export default Modal;