import React, { createContext, useContext } from "react";
import Axios from "axios";
import { API_ROOT } from "../Config";
import { AuthContext } from "./AuthContext";

const QueryContext = createContext();

const QueryProvider = ({ children }) => {
  const { userToken } = useContext(AuthContext);

  const downloadQuery = async (id, errorCallback = null) => {
    await Axios.post(`${API_ROOT}/queries/${id}/download.csv`, null, {
      headers: { 'Authorization': 'Bearer ' + userToken },
      responseType: 'blob'
    })
    .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'result.csv');
        document.body.appendChild(link);
        link.click();
    })
    .catch(() => {
      if (errorCallback != null) {
        errorCallback();
      }
    })
  }

  return (
    <QueryContext.Provider value={{ downloadQuery }}>
      { children }
    </QueryContext.Provider>
  )
}

export { QueryContext, QueryProvider };