import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  makeStyles,
  Typography,
  TextField,
  TextareaAutosize,
} from '@material-ui/core';
import { BsChevronLeft, BsPencilSquare } from 'react-icons/bs';
import { IoClose, IoChevronDownOutline } from 'react-icons/io5';
import Modal from '@material-ui/core/Modal';
import Lottie from 'react-lottie';
import styles from '../../styles';
import { LotContext } from '../../contexts/LotContext';
import { MapContext } from '../../contexts/MapContext';
import SphCard from '../../components/SphCard';
import InfoTable from '../../components/InfoTable';
import Switch from '../../components/Switch';
import loadingAnimation from '../../assets/images/loading.json';
import { LayerTypeContext } from 'src/contexts/LayerTypeContext';
import { LayerContext } from 'src/contexts/LayerContext';
import findLayerType from 'src/utils/findLayerType';
import numberWithCommas from 'src/utils/numberWithCommas';
import useWindowSize from 'src/utils/useWindowSize';
import dateFormat from 'src/utils/dateFormat';
import Diff from 'src/components/Diff';
import currentMonthString from 'src/utils/currentMonthString';

const useStyles = makeStyles(styles);

const lotLayerType = {
  felda: 'felda_lot',
  peneroka: 'lot',
};

function getModalStyle(width, height) {
  return {
    position: 'absolute',
    top: 25,
    left: width > 1000 ? (width - 1000) / 2 : 25,
    minHeight: 700,
    minWidth: 500,
    maxWidth: 1000,
    width: 'calc(100vw - 50px)',
    height: 'calc(100vh - 50px)',
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    outline: 'none',
  };
}

const getStatus = (status) => {
  if (status === 'pending') {
    return 'Belum diluluskan';
  } else if (status === 'approved') {
    return 'Diluluskan';
  } else if (status === 'rejected') {
    return 'Ditolak';
  }
  return 'undefined';
};

const Lot = (props) => {
  const classes = useStyles();
  const size = useWindowSize();
  const { program_id, id, lot_layer_name } = useParams();
  const { lot, getLot } = useContext(LotContext);
  const { flyTo, getLotTiles } = useContext(MapContext);
  const { layerTypes, getLayerTypes } = useContext(LayerTypeContext);
  const { updateLayer } = useContext(LayerContext);
  const [loading, setLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [toggleAddNotes, setToggleAddNotes] = useState(false);
  const [notes, setNotes] = useState('');
  const [toggleSuccessMessage, setToggleSuccessMessage] = useState(false);
  const [matang, setMatang] = useState(false);
  const [berhasil, setBerhasil] = useState(false);
  const [beratBersih, setBeratBersih] = useState('');

  useEffect(() => {
    getLot(id, () => setLoading(false));
    getLayerTypes(program_id);
  }, []);

  useEffect(() => {
    if (!loading) {
      getLotTiles(program_id, id, lotLayerType[lot_layer_name]);
      flyTo(lot.longitude, lot.latitude, 17);
    }
  }, [loading]);

  useEffect(() => {
    if (lot != null) {
      setMatang(lot.mature == true || lot.mature == 'true');
      setBerhasil(lot.productive == true || lot.productive == 'true');
      setBeratBersih(lot.hasil || '');
    }
  }, [lot]);

  const handleToggleProductive = () => {
    const params = {
      productive: lot.productive != true,
    };

    updateLayer(id, { layer: params });
  };

  const handleToggleMature = () => {
    const params = {
      mature: lot.mature != true,
    };

    updateLayer(id, { layer: params });
  };

  const handleOpenModal = () => {
    setShowEditModal(true);
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
  };

  const handleShowSuccessMessage = () => {
    setToggleSuccessMessage(true);
    setTimeout(function () {
      setToggleSuccessMessage(false);
    }, 2000);
  };

  const handleSubmitEdit = () => {
    const params = {
      layer: {
        mature: matang,
        productive: berhasil,
        hasil: beratBersih,
      },
    };

    updateLayer(id, params, (res) => {
      getLot(id);
      setNotes('');
      setToggleAddNotes(false);
      handleShowSuccessMessage();
    });
  };

  return (
    <>
      <Helmet>
        <title>Lot</title>
      </Helmet>

      <Modal open={showEditModal} onClose={handleCloseModal}>
        <div style={getModalStyle(size.width, size.height)}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={handleCloseModal} style={{ marginRight: 10 }}>
                <IoClose size={'25px'} color={'#FA9961'} />
              </IconButton>
              <Typography className={classes.subheading}>Lapisan {id}</Typography>
            </div>
            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
              {toggleSuccessMessage && (
                <Typography className={classes.body} style={{ color: 'green', marginRight: 10 }}>
                  Perubahan berjaya dihantar.
                </Typography>
              )}
              <Button disableRipple onClick={() => setToggleAddNotes(!toggleAddNotes)}>
                {toggleAddNotes ? 'Kembali' : 'Seterusnya'}
              </Button>
              {toggleAddNotes && <Button onClick={handleSubmitEdit}>Hantar</Button>}
            </div>
          </div>

          {toggleAddNotes ? (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 20, margin: '0 20px 0 20px' }}>
              <div>
                <Typography className={classes.body} style={{ width: 100 }}>
                  Nota
                </Typography>
                <TextareaAutosize
                  className={classes.textInput}
                  value={notes}
                  onChange={(val) => {
                    setNotes(val.target.value);
                  }}
                  minRows={5}
                  style={{ width: '100%', marginTop: 10, border: 0 }}
                />
              </div>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
                margin: '0 20px 0 20px',
                overflowY: 'scroll',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.body} style={{ width: 300 }}>
                  Nombor Lot
                </Typography>
                <span>{lot?.name}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.body} style={{ width: 290 }}>
                  Matang
                </Typography>
                <Switch checked={matang} onChange={(e) => setMatang(e.target.checked)} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.body} style={{ width: 290 }}>
                  Berhasil
                </Typography>
                <Switch checked={berhasil} onChange={(e) => setBerhasil(e.target.checked)} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.body} style={{ width: 300 }}>
                  Berat Bersih Hasil Sawit (Tan)
                </Typography>
                <TextField
                  placeholder='0'
                  className={classes.textInput}
                  InputProps={{ disableUnderline: true }}
                  value={beratBersih}
                  onChange={(e) => setBeratBersih(e.target.value)}
                />
              </div>
              <Accordion elevation={0} classes={{ root: classes.MuiAccordionroot }}>
                <AccordionSummary
                  expandIcon={<IoChevronDownOutline size={'25px'} />}
                  classes={{ root: classes.MuiAccordionSummaryRoot }}
                >
                  <Typography className={classes.body} style={{ width: 200 }}>
                    Perubahan lalu
                  </Typography>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.MuiAccordionDetailsRoot }}>
                  {lot?.changes.length > 0 ? (
                    <table className='changesTable'>
                      <tr style={{ textAlign: 'left' }}>
                        <th>Tarikh</th>
                        <th>Perubahan</th>
                        <th>Nota</th>
                        <th>Status</th>
                      </tr>
                      {lot.changes.map((item, index) => (
                        <tr style={{ borderBottom: index == lot.changes.length - 1 ? null : '1px solid grey' }}>
                          <td>{dateFormat(new Date(item.created_at), 'd/m/yyyy')}</td>
                          <td>
                            <Diff diff={item.diff} layerTypes={layerTypes} />
                          </td>
                          <td>{item.notes || '-'}</td>
                          <td>{getStatus(item.state)}</td>
                        </tr>
                      ))}
                    </table>
                  ) : (
                    <span>Tiada</span>
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>
      </Modal>

      <div style={{ paddingLeft: '40px', paddingRight: '40px', paddingTop: '20px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <IconButton onClick={() => props.history.goBack()} style={{ marginLeft: '-12px' }}>
            <BsChevronLeft size={'20px'} color={'#FA9961'} />
          </IconButton>
          {/*
            <IconButton onClick={handleOpenModal}>
              <BsPencilSquare size={"20px"} color={"#FA9961"}/>
            </IconButton>  
            */}
        </div>
        {!loading ? (
          <div style={{ height: 'calc(100vh - 5rem - 70px)', overflow: 'scroll' }}>
            <div className={classes.searchResult} style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography className={classes.heading}>{lot.name}</Typography>
                {lot.farmer_name != null ? (
                  <>
                    <Typography className={classes.body}>{lot.farmer_name}</Typography>
                    <Typography className={classes.body}>{lot.farmer_ic}</Typography>
                  </>
                ) : (
                  <Typography className={classes.body}>Tiada peneroka</Typography>
                )}
              </div>

              <SphCard sph={lot.sph} />
            </div>

            <Typography className={classes.subheading}>Statistik</Typography>

            <InfoTable style={{ marginBottom: '20px' }}>
              <InfoTable.Row>
                <InfoTable.Col
                  title={'Jumlah Keluasan Ladang Sawit'}
                  data={numberWithCommas(parseFloat(lot.total_area).toFixed(2))}
                  unit={'hektar'}
                />
                <InfoTable.Col
                  title={'Keluasan Boleh Tanam'}
                  data={numberWithCommas(parseFloat(lot.plantable_area).toFixed(2))}
                  unit={'hektar'}
                />
              </InfoTable.Row>

              <InfoTable.Row>
                <InfoTable.Col title={'Dirian Pokok'} data={numberWithCommas(lot.tree_count)} />
                <InfoTable.Col title={'SPH'} data={lot.sph} />
              </InfoTable.Row>

              <InfoTable.Row>
                <InfoTable.Col title={'Dirian Kosong'} data={numberWithCommas(lot.vacant_point_count)} />
                <InfoTable.Col title={'Pokok Tidak Sihat'} data={numberWithCommas(lot.unhealthy_count)} />
              </InfoTable.Row>

              <InfoTable.Row>
                <InfoTable.Col
                  title={`Berat Bersih Bulan ${currentMonthString()}`}
                  data={numberWithCommas(lot.berat_bersih_bulan_terkini) || 'N/A'}
                  unit={'tan'}
                />
                <InfoTable.Col
                  title={'Keluasan Hasil'}
                  data={numberWithCommas(lot.keluasan_hasil) || 'N/A'}
                  unit={'hektar'}
                />
              </InfoTable.Row>

              <InfoTable.Row>
                <InfoTable.Col
                  title={`Berat Bersih Per Hektar Bulan ${currentMonthString()}`}
                  data={numberWithCommas(lot.berat_bersih_per_hektar_bulan_terkini) || 'N/A'}
                  unit={'tan/ha'}
                />
              </InfoTable.Row>
            </InfoTable>

            <Typography className={classes.subheading}>Status</Typography>
            <div className={classes.rowWithTwoColumns}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography className={classes.body}>Berhasil</Typography>
                <Typography className={classes.secondary}>Adakah tanaman berhasil?</Typography>
              </div>

              <Switch checked={lot.productive} onChange={handleToggleProductive} />
            </div>
            <div
              className={classes.rowWithTwoColumns}
              style={{ paddingBottom: '20px', borderBottom: '3px solid #F0F1F5', marginBottom: '20px' }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography className={classes.body}>Matang</Typography>
                <Typography className={classes.secondary}>Adakah tanaman lebih 5 tahun?</Typography>
              </div>
              <Switch checked={lot.mature} onChange={handleToggleMature} />
            </div>
          </div>
        ) : (
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: loadingAnimation,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={200}
            width={200}
          />
        )}
      </div>
    </>
  );
};

export default Lot;
