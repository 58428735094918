import React, { useContext, useState, useMemo } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { DrawLineStringMode, DrawPolygonMode, EditingMode } from "react-map-gl-draw";
import { BiMinus, BiPlus, BiLayerPlus } from "react-icons/bi";
import { FaRuler, FaDrawPolygon, FaPencilRuler } from "react-icons/fa";
import { FiLayers } from "react-icons/fi";
import { MapContext } from "../contexts/MapContext";
import { LayerTypeContext } from '../contexts/LayerTypeContext';
import Modal from "../components/Modal";
import Switch from "../components/Switch";

const styles = {
  controlGroup: {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    bottom: "50px",
    right: "10px",
  },
  controlButton: {
    backgroundColor: "#FFFFFF",
    width: "45px",
    height: "45px",
    marginTop: "10px",
    borderRadius: "10px",
    boxShadow: "2px 2px 4px -2px rgba(0,0,0,.2)",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F0F1F5"
    }
  },
  controlButtonActive: {
    backgroundColor: "#FA9961",
    width: "45px",
    height: "45px",
    marginTop: "10px",
    borderRadius: "10px",
    boxShadow: "2px 2px 4px -2px rgba(0,0,0,.2)",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FFBE99"
    }
  },
  layerControlRow: {
    paddingTop: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  circle: {
    width: "12px",
    height: "12px",
    borderRadius: "6px",
    margin: "0 15px 0 10px"
  },
  line: {
    width: "22px",
    height: "6px",
    margin: "0 10px 0 5px"
  },
  thickLine: {
    width: "22px",
    height: "10px",
    margin: "0 10px 0 5px"
  },
  lotNumber: {
    textAlign: 'center',
    width: 30,
    height: 17,
    backgroundColor: 'lightgrey',
    marginRight: 10,
    fontSize: 13
  }
}

const useStyles = makeStyles(styles);

const Circle = ({ color }) => {
  const circleStyles = useMemo(() => ({
    ...styles.circle,
    ...({ backgroundColor: color })
  }), [])

  return (
    <div style={circleStyles} />
  )
}

const Line = ({ color, thick }) => {
  const lineStyles = useMemo(() => ({
    ...(thick ? styles.thickLine : styles.line),
    ...({ backgroundColor: color })
  }), [])

  return (
    <div style={lineStyles} />
  )
}

const getLayerIcon = (shape, style) => {
  if (shape === 'fill') {
    return <Line color={style.fillColor} thick  />
  } else if (shape === 'line') {
    return <Line color={style.lineColor} />
  } else if (shape === 'circle') {
    return <Circle color={style.circleColor} />
  }
}

const MapControls = () => {
  const classes = useStyles();
  const {
    viewport,
    drawingModeId,
    drawingMode,
    newLayerMode,
    setViewport,
    setDrawingModeId,
    setDrawingMode,
    setNewLayerMode,
    setNewLayerCoordinate,
    layerToShowIds,
    setLayerToShowIds
  } = useContext(MapContext);
  const { layerTypes } = useContext(LayerTypeContext);
  const [showLayers, setShowLayers] = useState(false);

  const handleToggleLayers = () => {
    setShowLayers(!showLayers);
  }

  const handleZoomIn = () => {
    const newZoomLevel = viewport.zoom + 1;
    setViewport({ ...viewport, zoom: newZoomLevel });
  }

  const handleZoomOut = () => {
    const newZoomLevel = viewport.zoom - 1;
    setViewport({ ...viewport, zoom: newZoomLevel });
  }

  const handleToggleDrawingMode = () => {
    if (drawingMode && drawingModeId !== "drawLine") {
      setDrawingModeId("drawLine");
      setDrawingMode(new DrawLineStringMode());
    } else {
      setDrawingModeId("editing");
    }
  }

  const handleToggleDrawingPolygonMode = () => {
    if (drawingMode && drawingModeId !== "drawPolygon") {
      setDrawingModeId("drawPolygon");
      setDrawingMode(new DrawPolygonMode());
    } else {
      setDrawingModeId("editing");
    }
  }

  const handleToggleNewLayerMode = () => {
    if (newLayerMode) {
      setNewLayerMode(false);
      setNewLayerCoordinate(null);
      setDrawingModeId(null)
      setDrawingMode(null)
    } else {
      setDrawingModeId('newLayer')
      setDrawingMode(new EditingMode())
      setNewLayerMode(true);
    }
  }

  const toggleEditMode = () => {
    if (drawingModeId && drawingModeId !== 'newLayer') {
      setDrawingMode(null)
      setDrawingModeId(null);
    } else {
      setNewLayerMode(false);
      setNewLayerCoordinate(null);
      setDrawingModeId('editing')
      setDrawingMode(new EditingMode())
    }
  }

  const onToggleLayerVisibility = (event, item) => {
    const { checked } = event.target;
    if (checked) {
      setLayerToShowIds([...layerToShowIds, item.id])
    } else {
      const newLayerIds = layerToShowIds.filter((i) => i !== item.id);
      setLayerToShowIds(newLayerIds)
    }
  }

  return (
    <>
      <Modal open={showLayers} onClose={() => setShowLayers(false)} title={"Lapisan"}>
        {
          layerTypes.map((item) => {
            return (
              <div key={item.id} className={classes.layerControlRow}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                  {getLayerIcon(item.shape, item.style)}
                  <Typography>{item.name}</Typography>
                </div>
                <Switch
                  checked={layerToShowIds.find((i) => i === item.id)}
                  onChange={(e) => { onToggleLayerVisibility(e, item) }}
                />
              </div>
            )
          })
        }
      </Modal>
      <div className={classes.controlGroup}>
        <div className={classes.controlButton} onClick={handleZoomIn}>
          <BiPlus size={"25px"} color={"#FA9961"}/>
        </div>
        <div className={classes.controlButton} onClick={handleZoomOut}>
          <BiMinus size={"25px"} color={"#FA9961"}/>
        </div>
        <div className={classes.controlButton} onClick={handleToggleLayers}>
          <FiLayers size={"25px"} color={"#FA9961"}/>
        </div>
        <div style={{ position: 'relative' }}>
          <div className={(drawingModeId && drawingModeId !== 'newLayer') ? classes.controlButtonActive : classes.controlButton} onClick={toggleEditMode}>
            <FaPencilRuler size={"25px"} color={(drawingModeId && drawingModeId !== 'newLayer') ? "#FFFFFF" : "#FA9961"}/>
          </div>
          <div style={{ position: 'absolute', right: 50, top: 0, display: (drawingModeId && drawingModeId !== 'newLayer') ? 'block': 'none' }}>
            <div className={drawingModeId == "drawLine" ? classes.controlButtonActive : classes.controlButton} onClick={handleToggleDrawingMode}>
              <FaRuler size={"25px"} color={drawingModeId == "drawLine" ? "#FFFFFF" : "#FA9961"}/>
            </div>
            <div className={drawingModeId == "drawPolygon" ? classes.controlButtonActive : classes.controlButton} onClick={handleToggleDrawingPolygonMode}>
              <FaDrawPolygon size={"25px"} color={drawingModeId == "drawPolygon" ? "#FFFFFF" : "#FA9961"}/>
            </div>
          </div>
        </div>
        <div className={newLayerMode ? classes.controlButtonActive : classes.controlButton} onClick={handleToggleNewLayerMode}>
          <BiLayerPlus size={"25px"} color={newLayerMode ? "#FFFFFF" : "#FA9961"}/>
        </div>
      </div>
    </>
  )
}

export default MapControls;
