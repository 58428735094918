import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, useLocation } from "react-router-dom";
import Lottie from 'react-lottie';
import { Button, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { FaSlidersH } from "react-icons/fa";
import { BiInfoCircle } from "react-icons/bi";

import styles from "src/styles";
import loadingAnimation from "src/assets/images/loading.json";
import { LotContext } from "src/contexts/LotContext";
import { MapContext } from "src/contexts/MapContext";
import SphCard from "src/components/SphCard";
import Modal from "src/components/Modal";
import Chip from "src/components/Chip";
import SphLegendItem from 'src/components/SphLegendItem';
import findLayerType from "src/utils/findLayerType";
import { LayerTypeContext } from "src/contexts/LayerTypeContext";

const useStyles = makeStyles(styles);

const lotLayerType = {
  felda: 'felda_lot',
  peneroka: 'lot'
}

const Lots = (props) => {
  const classes = useStyles();
  const { id, lot_layer_name } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const page = parseInt(query.get("page")) || 1;
  const sphFilter = query.get("sph");
  const keyword = query.get("keyword");
  const { lots, lotsTotalCount, lotsTotalPages, getLots } = useContext(LotContext);
  const { getLayerTypes } = useContext(LayerTypeContext);
  const { getLotsTiles } = useContext(MapContext);
  const [loading, setLoading] = useState(true);
  const [sphGoodFilter, setSphGoodFilter] = useState(false);
  const [sphMediumFilter, setSphMediumFilter] = useState(false);
  const [sphBadFilter, setSphBadFilter] = useState(false);
  const [layerTypeId, setLayerTypeId] = useState(null);
  const [modal, setModal] = useState({
    open: false,
    title: null,
    children: null
  })

  useEffect(() => {
    if (layerTypeId != null) {
      fetchLots(layerTypeId);
    } else {
      getLayerTypes(id, (newLayerTypes) => {
        if (newLayerTypes.length > 0) {
          const foundLayerType = findLayerType(lotLayerType[lot_layer_name], newLayerTypes);
          setLayerTypeId(foundLayerType.id);
          fetchLots(foundLayerType.id);
        }
      });
    }
  }, [page, sphFilter, keyword])

  useEffect(() => {
    getLayerTypes(id, (newLayerTypes) => {
      if (newLayerTypes.length > 0) {
        const foundLayerType = findLayerType(lotLayerType[lot_layer_name], newLayerTypes);
        setLayerTypeId(foundLayerType.id);
        fetchLots(foundLayerType.id);
      }
    });
  }, [lot_layer_name])

  useEffect(() => {
    if (sphGoodFilter) {
      setSphMediumFilter(false);
      setSphBadFilter(false);
    }
  }, [sphGoodFilter])

  useEffect(() => {
    if (sphMediumFilter) {
      setSphGoodFilter(false);
      setSphBadFilter(false);
    }
  }, [sphMediumFilter])

  useEffect(() => {
    if (sphBadFilter) {
      setSphGoodFilter(false);
      setSphMediumFilter(false);
    }
  }, [sphBadFilter])

  const fetchLots = (typeId) => {
    let filter = null;

    if (sphFilter == "good" || sphFilter == "medium" || sphFilter == "bad") {
      switch (sphFilter) {
        case ("good"):
          setSphGoodFilter(true);
          break
        case ("medium"):
          setSphMediumFilter(true);
          break
        case ("bad"):
          setSphBadFilter(true);
          break
      }
      filter = sphFilter;
    }
    setLoading(true);
    getLots(typeId, page, filter, keyword, (newLots) => {
      var newLotsIds = newLots.map((newLot) => newLot.id);
      getLotsTiles(id, newLotsIds, lotLayerType[lot_layer_name]);
      setLoading(false);
    });
  }

  const handleChangePage = (event, value) => {
    let url = `/programs/${id}/lots/${lot_layer_name}?page=${value}`;

    if (sphFilter != null && (sphFilter == "good" || sphFilter == "medium" || sphFilter == "bad")) {
      url = url + `&sph=${sphFilter}`;
    }

    if (keyword != null && keyword != "") {
      url = url + `&keyword=${keyword}`;
    }

    props.history.push(url);
  }

  const handleClearFilter = () => {
    setSphGoodFilter(false);
    setSphMediumFilter(false);
    setSphBadFilter(false);
  }

  const handleApplyFilter = () => {
    let url = `/programs/${id}/lots/${lot_layer_name}?page=1`;

    if (sphGoodFilter) {
      url = url + '&sph=good'
    } else if (sphMediumFilter) {
      url = url + '&sph=medium'
    } else if (sphBadFilter) {
      url = url + '&sph=bad'
    }

    if (keyword != null && keyword != "") {
      url = url + `&keyword=${keyword}`;
    }

    props.history.push(url);
    handleCloseModal()
  }

  const handleSetSphLegendModal = () => {
    setModal({
      open: true,
      title: 'Jenis warna SPH',
      children: <SphLegend />
    })
  }

  const handleSetFilterModal = () => {
    setModal({
      open: true,
      title: 'Tapis',
      children: <SphFilter />,
    })
  }

  const handleCloseModal = () => {
    setModal({
      open: false,
      children: null
    })
  }

  const SphLegend = () => (
    <div style={{ margin: 20 }}>
      <SphLegendItem color="#7ED321" description="Bagus" />
      <SphLegendItem color="#F9DB38" description="Sederhana" />
      <SphLegendItem color="#EB725E" description="Kurang baik" />
    </div>
  )

  const SphFilter = () => (
    <>
      <Typography className={classes.subheading}>SPH</Typography>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", paddingTop: "10px"}}>
        <Chip label={"Bagus"} active={sphGoodFilter} callback={() => setSphGoodFilter(!sphGoodFilter)}/>
        <Chip label={"Sederhana"} active={sphMediumFilter} callback={() => setSphMediumFilter(!sphMediumFilter)} style={{marginLeft: "10px"}}/>
        <Chip label={"Kurang baik"} active={sphBadFilter} callback={() => setSphBadFilter(!sphBadFilter)} style={{marginLeft: "10px"}}/>
      </div>
      <div style={{marginTop: "10px"}}>
        <Typography className={classes.link} onClick={handleClearFilter}>Clear</Typography>
      </div>
      <Button style={{position: "absolute", bottom: 20, left: 20, right: 20}} onClick={() => handleApplyFilter()}>Apply</Button>
      <Chip label={"Bagus"} active={false} callback={() => {}}/>
    </>
  )

  return (
    <>
      <Helmet>
        <title>Carian Lot</title>
      </Helmet>

      <div style={{paddingLeft: "40px",paddingRight: "40px",paddingTop: "20px"}}>
        {
          loading ? (
            <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: loadingAnimation,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
                height={200}
                width={200}
            />
          ) : (
            <>
              <div style={{marginBottom: "10px", alignItems: "center", justifyContent: "space-between", display: "flex", flexDirection: "row"}}>
                <Typography className={classes.bold}>{`${lotsTotalCount} Lot`}</Typography>
                <div>
                  <IconButton onClick={handleSetSphLegendModal}>
                    <BiInfoCircle size={"1.3rem"} color={"#FA9961"} />
                  </IconButton>
                  <IconButton onClick={handleSetFilterModal}>
                    <FaSlidersH size={"1.3rem"} color={"#FA9961"} />
                  </IconButton>
                </div>
              </div>
              <div style={{height: "calc(100vh - 5rem - 80px)", overflow: "scroll"}}>
                {
                  lots.map((lot, index) => (
                    <div key={index} onClick={() => props.history.push(`/programs/${id}/lots/${lot_layer_name}/${lot.id}`)} className={classes.searchResult} style={{borderTop: index == 0 ? "3px solid #F0F1F5" : null}}>
                      <div style={{display: "flex", flexDirection: "column"}}>
                        <Typography className={classes.info}>{ lot.name }</Typography>
                        {
                          lot.farmer_name != null ? (
                            <>
                              <Typography className={classes.body}>{lot.farmer_name}</Typography>
                              <Typography className={classes.body}>{lot.farmer_ic}</Typography>
                            </>
                          ) : (
                            <Typography className={classes.body}>Tiada peneroka</Typography>
                          )
                        }
                        <Typography className={classes.secondary}>{`${lot.tree_count} pokok · ${lot.unhealthy_count} tidak sihat · ${lot.vacant_point_count} dirian kosong`}</Typography>
                      </div>

                      <SphCard sph={lot.sph} />
                    </div>
                  ))
                }
                <div style={{marginTop: "20px", marginBottom: "20px", display: "flex", justifyContent: "center"}}>
                  <Pagination page={page} count={lotsTotalPages} onChange={handleChangePage}/>
                </div>
              </div>
            </>
          )
        }
      </div>

      <Modal open={modal.open} onClose={handleCloseModal} title={modal.title}>
        {modal.children}
      </Modal>
    </>
  )
}

export default Lots;
