import React, { createContext, useContext, useState } from "react";
import Axios from "axios";
import { API_ROOT } from "../Config";
import { AuthContext } from "./AuthContext";

const ChangeContext = createContext();

const ChangeProvider = ({ children }) => {
  const { userToken } = useContext(AuthContext);
  const [changes, setChanges] = useState([]);
  const [changesTotalPages, setChangesTotalPages] = useState(1);
  const [changesTotalCount, setChangesTotalCount] = useState(1);

  const getChanges = async (entityId, page = 1) => {
    await Axios.get(`${API_ROOT}/maps/${entityId}/layers/changes/?page=${page}`, {
      headers: { 'Authorization': 'Bearer ' + userToken }
    })
    .then((response) => {
      const paginationHeader = JSON.parse(response.headers['x-pagination']);
      setChangesTotalCount(paginationHeader.total_count);
      setChangesTotalPages(paginationHeader.total_pages);
      setChanges(response.data.data);
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const approveChange = async (id) => {
    await Axios.put(`${API_ROOT}/changes/${id}/approve`, null, {
      headers: { 'Authorization': 'Bearer ' + userToken }
    })
    .then((response) => {
      const approvedChange = response.data.data;
      let changesDup = [...changes];
      let changeIndex = changesDup.findIndex(changeDup => changeDup.id == approvedChange.id);
      changesDup[changeIndex] = approvedChange;
      setChanges(changesDup);
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const rejectChange = async (id) => {
    await Axios.put(`${API_ROOT}/changes/${id}/reject`, null, {
      headers: { 'Authorization': 'Bearer ' + userToken }
    })
    .then((response) => {
      const rejectedChange = response.data.data;
      let changesDup = [...changes];
      let changeIndex = changesDup.findIndex(changeDup => changeDup.id == rejectedChange.id);
      changesDup[changeIndex] = rejectedChange;
      setChanges(changesDup);
    })
    .catch((error) => {
      console.log(error)
    })
  }

  return (
    <ChangeContext.Provider
      value={{
        changes,
        changesTotalCount,
        changesTotalPages,
        getChanges,
        approveChange,
        rejectChange
      }}
    >
      { children }
    </ChangeContext.Provider>
  )
}

export { ChangeContext, ChangeProvider };
