import React, { createContext, useContext, useState } from "react";
import Axios from "axios";
import { API_ROOT, MOSAIC_ROOT } from "../Config";
import { AuthContext } from "./AuthContext";
import { MapContext } from "./MapContext";

const ProgramContext = createContext();

const ProgramProvider = ({ children }) => {
  const { userToken } = useContext(AuthContext);
  const { viewport, setViewport } = useContext(MapContext);
  const [territoriesList, setTerritoriesList] = useState([]);
  const [selectedProgramId, setSelectedProgramId] = useState(null);
  const [program, setProgram] = useState(null);
  const [programMosaicBucketFolders, setProgramMosaicBucketFolders] = useState([]);

  const getTerritories = async () => {
    await Axios.get(`${API_ROOT}/maps?per_page=500`, {
      headers: { 'Authorization': 'Bearer ' + userToken }
    })
    .then((response) => {
      setTerritoriesList(response.data.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0) ));
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const getProgram = async (id) => {
    await Axios.get(`${API_ROOT}/maps/${id}`, {
      headers: { 'Authorization': 'Bearer ' + userToken }
    })
    .then((response) => {
      const newProgram = response.data.data;

      let folders = [];
      for (var folderName of newProgram.mosaic_bucket_folders) {
        let folder = `${MOSAIC_ROOT}/${newProgram.mosaic_bucket_name}/${newProgram.id}/${folderName}/{z}/{x}/{y}.png`;
        folders.push(folder);
      }
      setProgramMosaicBucketFolders(folders);

      setViewport({
        ...viewport,
        longitude: parseFloat(newProgram.longitude),
        latitude: parseFloat(newProgram.latitude),
        zoom: newProgram.zoom_level
      });

      setProgram(newProgram);
    })
    .catch((error) => {
      console.log(error)
    })
  }

  return (
    <ProgramContext.Provider
      value={{
        territoriesList,
        selectedProgramId,
        program,
        programMosaicBucketFolders,
        getTerritories,
        setSelectedProgramId,
        getProgram
      }}
    >
      { children }
    </ProgramContext.Provider>
  )
}

export { ProgramContext, ProgramProvider };
