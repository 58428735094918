import React, { createContext, useContext, useState } from "react";
import Axios from "axios";
import { API_ROOT } from "../Config";
import { AuthContext } from "./AuthContext";
import { ProgramContext } from "./ProgramContext";

const LotContext = createContext();

const LotProvider = ({ children }) => {
  const { userToken } = useContext(AuthContext);
  const { selectedProgramId } = useContext(ProgramContext);
  const [lotsTotalPages, setLotsTotalPages] = useState(1);
  const [lotsTotalCount, setLotsTotalCount] = useState(1);
  const [lots, setLots] = useState([]);
  const [lot, setLot] = useState(null);
  const [lotIssues, setLotIssues] = useState([]);

  const getLots = async (type, page = 1, filter = null, keyword = null, callback = null) => {
    let url = `${API_ROOT}/layer_types/${type}/layers?page=${page}`
    if (filter == 'good') {
      url = url + '&sph[gt]=130'
    } else if (filter == 'medium') {
      url = url + '&sph[gt]=100&sph[lt]=129'
    } else if (filter == 'bad') {
      url = url + '&sph[lt]=99'
    }

    if (keyword != null && keyword != "") {
      url = url + `&or[[name[eq]]]=${keyword}&or[[farmer_name[eq]]]=${keyword}&or[[farmer_ic[eq]]]=${keyword}`;
    }
    setLots([]);
    await Axios.get(url, {
      headers: { 'Authorization': 'Bearer ' + userToken }
    })
    .then((response) => {
      const paginationHeader = JSON.parse(response.headers['x-pagination']);
      setLotsTotalCount(paginationHeader.total_count);
      setLotsTotalPages(paginationHeader.total_pages);
      setLots(response.data.data);
      if (callback != null) {
        callback(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const getLot = async (id, callback = null) => {
    await Axios.get(`${API_ROOT}/layers/${id}?centroid=1`, {
      headers: { 'Authorization': 'Bearer ' + userToken }
    })
    .then((response) => {
      setLot(response.data.data);
      if (callback != null) {
        callback(response.data.data);
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const clearLotIssues = () => {
    setLotIssues([]);
  }

  return (
    <LotContext.Provider
      value={{
        lot,
        lotIssues,
        lots,
        lotsTotalCount,
        lotsTotalPages,
        getLots,
        getLot,
        clearLotIssues
      }}
    >
      { children }
    </LotContext.Provider>
  )
}

export { LotContext, LotProvider };
