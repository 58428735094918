import React, { createContext, useState } from "react";
import Axios from "axios";
import { API_ROOT } from "../Config";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authChecked, setAuthChecked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [userToken, setUserToken] = useState("");

  const login = async (params, showErrorMessage) => {
    await Axios.post(`${API_ROOT}/login`, { user: params })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        setUserToken(response.data.token);
        setUser(response.data.user);
        setIsAuthenticated(true);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          showErrorMessage('Invalid credentials');
        } else if (error.response.status === 500) {
          showErrorMessage('Error');
        } else {
          console.log(error);
        }
      })
  }

  const autoLogin = async () => {
    const token = localStorage.getItem("token");

    if (token != null) {
      await Axios.get(`${API_ROOT}/auto_login`, {
        headers: { 'Authorization': 'Bearer ' + token }
      })
      .then((response) => {
        setUserToken(token);
        setUser(response.data.data);
        setIsAuthenticated(true);
      })
      .catch(error => {
        console.log(error);
        logout()
      })
    }

    setAuthChecked(true);
  }

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    setUserToken("");
    localStorage.removeItem("token");
  };

  const forgotPassword = async (params, successCallback, errorCallback) => {
    await Axios.post(`${API_ROOT}/users/password/forgot`, { user: params })
      .then((response) => {
        if (successCallback != null) {
          successCallback();
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          if (errorCallback != null) {
            errorCallback(error.response.data.error);  
          }
        } else {
          console.log(error);
        }
      })
  }

  const resetPassword = async (params, successCallback, errorCallback) => {
    await Axios.post(`${API_ROOT}/users/password/reset`, { user: params })
      .then((response) => {
        if (successCallback != null) {
          successCallback();
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          if (errorCallback != null) {
            errorCallback(error.response.data.error);  
          }
        } else {
          console.log(error);
        }
      }) 
  }

  const updatePassword = async (params, successCallback, errorCallback) => {
    await Axios.put(`${API_ROOT}/users/password`, { user: params }, {
      headers: { 'Authorization': 'Bearer ' + userToken }
    })
      .then((response) => {
        if (successCallback != null) {
          successCallback();
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          if (errorCallback != null) {
            errorCallback(error.response.data.error);  
          }
        } else {
          console.log(error);
        }
      })
  }

  return (
    <AuthContext.Provider
      value={{
        authChecked,
        isAuthenticated,
        user,
        userToken,
        login,
        autoLogin,
        logout,
        forgotPassword,
        resetPassword,
        updatePassword
      }}
    >
      { children }
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider };