import React, { createContext, useContext, useState } from "react";
import Axios from "axios";
import { API_ROOT } from "src/Config";
import { LayerTypeContext } from "./LayerTypeContext";
import findLayerType from "src/utils/findLayerType";
import camelCaseToKebabCase from "src/utils/camelCaseToKebabCase";

const MapContext = createContext();

const getLayerToShowIds = (layers) => {
  const visibleLayers = layers.filter(
    (layer) => [
      'felda_lot',
      'lot',
      'block',
      'level',
      'main_road',
      'tree_point',
      'river',
      'vacant_point',
      'unhealthy_point',
      'unhealthy_point_in_progress'
    ].includes(layer.key)
  );
  return visibleLayers.map((item) => item.id);
}

const addHoverStyle = (style, type) => {
  if (type == "fill") {
    return { ...style, ...{ "fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 1, 0.5] } }
  } else if (type == "line") {
    return { ...style, ...{ "line-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 1, 0.5] } }
  } else if (type == "circle") {
    return { ...style, ...{ "circle-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 1, 0.5] } }
  }
}

const MapProvider = ({ children }) => {
  const { getLayerTypes } = useContext(LayerTypeContext);
  const [viewport, setViewport] = useState({
    latitude: 3.956602,
    longitude: 101.405503,
    zoom: 12
  });
  const [drawingModeId, setDrawingModeId] = useState(null);
  const [drawingMode, setDrawingMode] = useState(null);
  const [newLayerMode, setNewLayerMode] = useState(false);
  const [newLayerCoordinate, setNewLayerCoordinate] = useState(null);
  const [mainLayersUrls, setMainLayersUrls] = useState([]);
  const [infraLayersUrls, setInfraLayersUrls] = useState([]);
  const [searchedCoordinate, setSearchedCoordinate] = useState([]);
  const [layerToShowIds, setLayerToShowIds] = useState([]);
  const [mapLoading, setMapLoading] = useState(false);

  const flyTo = (longitude, latitude, zoom) => {
    setViewport({
      ...viewport,
      longitude: longitude,
      latitude: latitude,
      zoom: zoom
    });
  };

  const getProgramTiles = (id) => {
    if (id == null) {
      return
    }

    getLayerTypes(id, (newLayerTypes) => {
      setMapLoading(true);
      if (newLayerTypes.length > 0) {
        var mainUrlsDup = [];
        var infraUrlsDup = [];
        setLayerToShowIds(getLayerToShowIds(newLayerTypes));

        for ( const x of ['lot', 'felda_lot', 'tree_point', 'unhealthy_point', 'unhealthy_point_in_progress', 'vacant_point']) {
          const main = findLayerType(x, newLayerTypes);

          if (main != null) {
            mainUrlsDup.push({
              id: main.id,
              key: main.key,
              type: main.shape,
              url: `${API_ROOT}/layer_types/${main.id}/layers/geojson.geojson`,
              paint: addHoverStyle(camelCaseToKebabCase(main.style), main.shape)
            })
          }
        }
        setMainLayersUrls(mainUrlsDup);

        for ( const x of [
          'main_road',
          'dirt_road',
          'dirt_trail',
          'river',
          'culvert',
          'others',
          'elephant_fence',
          'energized_fence',
          'watchtower',
          'water_source',
          'gazebo',
          'bridge',
          'warta',
          'warta_rancangan',
          'warta_ladang',
          'warta_kampung',
          'kawasan_bukan_lot_peneroka',
          'batu_sempadan',
          'kontur',
          'saliran'
        ]) {
          const infra = findLayerType(x, newLayerTypes);

          if (infra != null) {
            infraUrlsDup.push({
              id: infra.id,
              key: infra.key,
              type: infra.shape,
              url: `${API_ROOT}/layer_types/${infra.id}/layers/geojson.geojson`,
              paint: camelCaseToKebabCase(infra.style)
            })
          }
        }

        setInfraLayersUrls(infraUrlsDup);
        setMapLoading(false);
      }
    });
  }

  const getLevelsTiles = (id, layerIds = null) => {
    if (id == null || layerIds == null) {
      return
    }

    if (layerIds.length == 0) {
      setMainLayersUrls([]);
      return
    }

    let polygonParams = '?id[]=' + layerIds.join('&id[]=')
    let pointsParams = '?parent_id[]=' + layerIds.join('&parent_id[]=')

    getLayerTypes(id, (newLayerTypes) => {
      setMapLoading(true);
      if (newLayerTypes.length > 0) {
        var mainUrlsDup = [];
        setLayerToShowIds(getLayerToShowIds(newLayerTypes));

        const polygon = findLayerType('level', newLayerTypes);

        if (polygon != null) {
          mainUrlsDup.push({
            id: polygon.id,
            key: polygon.key,
            type: polygon.shape,
            url: `${API_ROOT}/layer_types/${polygon.id}/layers/geojson.geojson${polygonParams}`,
            paint: addHoverStyle(camelCaseToKebabCase(polygon.style), polygon.shape)
          })
        }

        for ( const x of ['tree_point', 'unhealthy_point', 'unhealthy_point_in_progress', 'vacant_point']) {
          const point = findLayerType(x, newLayerTypes);

          if (point != null) {
            mainUrlsDup.push({
              id: point.id,
              key: point.key,
              type: point.shape,
              url: `${API_ROOT}/layer_types/${point.id}/layers/geojson.geojson${pointsParams}`,
              paint: addHoverStyle(camelCaseToKebabCase(point.style), point.shape)
            })
          }
        }

        setMainLayersUrls(mainUrlsDup);
        setMapLoading(false);
      }
    });
  }

  const getLevelTiles = (entityId, id) => {
    if (entityId == null || id == null) {
      return
    }

    getLayerTypes(entityId, (newLayerTypes) => {
      setMapLoading(true);
      if (newLayerTypes.length > 0) {
        var mainUrlsDup = [];
        setLayerToShowIds(getLayerToShowIds(newLayerTypes));

        const polygon = findLayerType('level', newLayerTypes);

        if (polygon != null) {
          mainUrlsDup.push({
            id: polygon.id,
            key: polygon.key,
            type: polygon.shape,
            url: `${API_ROOT}/layers/${id}/geojson.geojson`,
            paint: addHoverStyle(camelCaseToKebabCase(polygon.style), polygon.shape)
          })
        }

        for ( const x of ['tree_point', 'unhealthy_point', 'unhealthy_point_in_progress', 'vacant_point']) {
          const point = findLayerType(x, newLayerTypes);

          if (point != null) {
            mainUrlsDup.push({
              id: point.id,
              key: point.key,
              type: point.shape,
              url: `${API_ROOT}/layer_types/${point.id}/layers/geojson.geojson?parent_id[]=${id}`,
              paint: addHoverStyle(camelCaseToKebabCase(point.style), point.shape)
            })
          }
        }

        setMainLayersUrls(mainUrlsDup);
        setMapLoading(false);
      }
    });
  }

  const getBlocksTiles = (id, layerIds = null) => {
    if (id == null || layerIds == null) {
      return
    }

    if (layerIds.length == 0) {
      setMainLayersUrls([]);
      return
    }

    let polygonParams = '?id[]=' + layerIds.join('&id[]=')
    let pointsParams = '?parent_id[]=' + layerIds.join('&parent_id[]=')

    getLayerTypes(id, (newLayerTypes) => {
      setMapLoading(true);
      if (newLayerTypes.length > 0) {
        var mainUrlsDup = [];
        setLayerToShowIds(getLayerToShowIds(newLayerTypes));

        const polygon = findLayerType('block', newLayerTypes);

        if (polygon != null) {
          mainUrlsDup.push({
            id: polygon.id,
            key: polygon.key,
            type: polygon.shape,
            url: `${API_ROOT}/layer_types/${polygon.id}/layers/geojson.geojson${polygonParams}`,
            paint: addHoverStyle(camelCaseToKebabCase(polygon.style), polygon.shape)
          })
        }

        for ( const x of ['tree_point', 'unhealthy_point', 'unhealthy_point_in_progress', 'vacant_point']) {
          const point = findLayerType(x, newLayerTypes);

          if (point != null) {
            mainUrlsDup.push({
              id: point.id,
              key: point.key,
              type: point.shape,
              url: `${API_ROOT}/layer_types/${point.id}/layers/geojson.geojson${pointsParams}`,
              paint: addHoverStyle(camelCaseToKebabCase(point.style), point.shape)
            })
          }
        }

        setMainLayersUrls(mainUrlsDup);
        setMapLoading(false);
      }
    });
  }

  const getBlockTiles = (entityId, id) => {
    if (entityId == null || id == null) {
      return
    }

    getLayerTypes(entityId, (newLayerTypes) => {
      setMapLoading(true);
      if (newLayerTypes.length > 0) {
        var mainUrlsDup = [];
        setLayerToShowIds(getLayerToShowIds(newLayerTypes));

        const polygon = findLayerType('block', newLayerTypes);

        if (polygon != null) {
          mainUrlsDup.push({
            id: polygon.id,
            key: polygon.key,
            type: polygon.shape,
            url: `${API_ROOT}/layers/${id}/geojson.geojson`,
            paint: addHoverStyle(camelCaseToKebabCase(polygon.style), polygon.shape)
          })
        }

        for ( const x of ['tree_point', 'unhealthy_point', 'unhealthy_point_in_progress', 'vacant_point']) {
          const point = findLayerType(x, newLayerTypes);

          if (point != null) {
            mainUrlsDup.push({
              id: point.id,
              key: point.key,
              type: point.shape,
              url: `${API_ROOT}/layer_types/${point.id}/layers/geojson.geojson?parent_id[]=${id}`,
              paint: addHoverStyle(camelCaseToKebabCase(point.style), point.shape)
            })
          }
        }

        setMainLayersUrls(mainUrlsDup);
        setMapLoading(false);
      }
    });
  }

  const getLotsTiles = (id, layerIds = null, lotLayerType) => {
    if (id == null || layerIds == null) {
      return
    }

    if (layerIds.length == 0) {
      setMainLayersUrls([]);
      return
    }

    let polygonParams = '?id[]=' + layerIds.join('&id[]=')
    let pointsParams = '?parent_id[]=' + layerIds.join('&parent_id[]=')

    getLayerTypes(id, (newLayerTypes) => {
      setMapLoading(true);
      if (newLayerTypes.length > 0) {
        var mainUrlsDup = [];
        setLayerToShowIds(getLayerToShowIds(newLayerTypes));

        const polygon = findLayerType(lotLayerType, newLayerTypes);

        if (polygon != null) {
          mainUrlsDup.push({
            id: polygon.id,
            key: polygon.key,
            type: polygon.shape,
            url: `${API_ROOT}/layer_types/${polygon.id}/layers/geojson.geojson${polygonParams}`,
            paint: addHoverStyle(camelCaseToKebabCase(polygon.style), polygon.shape)
          })
        }

        for ( const x of ['tree_point', 'unhealthy_point', 'unhealthy_point_in_progress', 'vacant_point']) {
          const point = findLayerType(x, newLayerTypes);

          if (point != null) {
            mainUrlsDup.push({
              id: point.id,
              key: point.key,
              type: point.shape,
              url: `${API_ROOT}/layer_types/${point.id}/layers/geojson.geojson${pointsParams}`,
              paint: addHoverStyle(camelCaseToKebabCase(point.style), point.shape)
            })
          }
        }

        setMainLayersUrls(mainUrlsDup);
        setMapLoading(false);
      }
    });
  }

  const getLotTiles = (entityId, id, lotLayerType) => {
    if (entityId == null || id == null) {
      return
    }

    getLayerTypes(entityId, (newLayerTypes) => {
      setMapLoading(true);
      if (newLayerTypes.length > 0) {
        var mainUrlsDup = [];
        setLayerToShowIds(getLayerToShowIds(newLayerTypes));

        const polygon = findLayerType(lotLayerType, newLayerTypes);

        if (polygon != null) {
          mainUrlsDup.push({
            id: polygon.id,
            key: polygon.key,
            type: polygon.shape,
            url: `${API_ROOT}/layers/${id}/geojson.geojson`,
            paint: addHoverStyle(camelCaseToKebabCase(polygon.style), polygon.shape)
          })
        }

        for ( const x of ['tree_point', 'unhealthy_point', 'unhealthy_point_in_progress', 'vacant_point']) {
          const point = findLayerType(x, newLayerTypes);

          if (point != null) {
            mainUrlsDup.push({
              id: point.id,
              key: point.key,
              type: point.shape,
              url: `${API_ROOT}/layer_types/${point.id}/layers/geojson.geojson?parent_id[]=${id}`,
              paint: addHoverStyle(camelCaseToKebabCase(point.style), point.shape)
            })
          }
        }

        setMainLayersUrls(mainUrlsDup);
        setMapLoading(false);
      }
    });
  }

  return (
    <MapContext.Provider
      value={{
        viewport,
        drawingModeId,
        drawingMode,
        newLayerMode,
        newLayerCoordinate,
        mainLayersUrls,
        infraLayersUrls,
        mapLoading,
        setViewport,
        setDrawingModeId,
        setDrawingMode,
        setNewLayerMode,
        setNewLayerCoordinate,
        setMainLayersUrls,
        setInfraLayersUrls,
        flyTo,
        getProgramTiles,
        getLevelsTiles,
        getLevelTiles,
        getBlocksTiles,
        getBlockTiles,
        getLotsTiles,
        getLotTiles,
        searchedCoordinate,
        setSearchedCoordinate,
        layerToShowIds,
        setLayerToShowIds
      }}
    >
      { children }
    </MapContext.Provider>
  )
}

export { MapContext, MapProvider };
