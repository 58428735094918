const findLayerType = (param, layerTypes, method = 'key') => {
  var result;
  for (var layerType of layerTypes) {
    if (method == 'id') {
      if (layerType.id == param) {
        result = layerType;
      }
    } else {
      if (layerType.key == param) {
        result = layerType;
      }
    }
  }
  return result;
}

export default findLayerType;