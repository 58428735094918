import { Button, IconButton, makeStyles, MenuItem, Select, TextareaAutosize, TextField, Typography } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { IoClose } from "react-icons/io5";
import Modal from '@material-ui/core/Modal';
import styles from "../styles";
import { LayerContext } from "src/contexts/LayerContext";
import { LayerTypeContext } from "src/contexts/LayerTypeContext";
import { MapContext } from "src/contexts/MapContext";
import findLayerType from "src/utils/findLayerType";
import useWindowSize from 'src/utils/useWindowSize';
import { convert4326to3857 } from "src/utils/coordinatesConverter";
import FilesDropzone from './FilesDropzone';

const useStyles = makeStyles(styles);

function getModalStyle(width, height) {
  return {
    position: 'absolute',
    top: 25,
    left: width > 1000 ? (width - 1000) / 2 : 25,
    minHeight: 700,
    minWidth: 500,
    maxWidth: 1000,
    width: 'calc(100vw - 50px)',
    height: 'calc(100vh - 50px)',
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    outline: 'none'
  };
}

const NewLayerModal = (props) => {
  const {
    setNewLayerCoordinate,
    newLayerCoordinate,
    selectableLayerTypes,
    modalOpen,
    setModalOpen
  } = props;
  const { layerTypes } = useContext(LayerTypeContext);
  const { createLayer } = useContext(LayerContext);
  const { setNewLayerMode } = useContext(MapContext);
  const size = useWindowSize();
  const classes = useStyles();
  const [selectedNewLayerTypeId, setSelectedNewLayerTypeId] = useState("");
  const [toggleAddNotes, setToggleAddNotes] = useState(false);
  const [notes, setNotes] = useState("");
  const [name, setName] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const disableNext = selectedNewLayerTypeId == "";

  const handleCloseModal = () => {
    setNewLayerCoordinate(null);
    setModalOpen(false);
    setNewLayerMode(false);
  }

  const handleSubmit = () => {
    const geometry = convert4326to3857(`POINT (${newLayerCoordinate[0]} ${newLayerCoordinate[1]})`)

    const createFormData = new FormData();
    createFormData.append(`[layer]geometry`, geometry);
    createFormData.append(`[layer]name`, name);
    createFormData.append(`[layer]notes`, notes);

    if (uploadedImages.length > 0) {
      for (var uploadedImage of uploadedImages) {
        createFormData.append(`[layer]images[]`, uploadedImage);
      }
    }

    createLayer(selectedNewLayerTypeId, createFormData, () => handleCloseModal());
  }

  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
    >
      <div style={getModalStyle(size.width, size.height)}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={handleCloseModal} style={{ marginRight: 10 }}>
              <IoClose size={"25px"} color={"#FA9961"}/>
            </IconButton>
            <Typography className={classes.subheading}>Tambah lapisan baru</Typography>
          </div>
          <div style={{ display: 'flex', gap: 10 }}>
            <Button
              disableRipple
              style={{
                  backgroundColor: (disableNext || toggleAddNotes) && 'transparent',
                  color: disableNext ? 'lightgrey' : (toggleAddNotes && 'black')
                }}
              onClick={() => setToggleAddNotes(!toggleAddNotes)}
              disabled={disableNext}
            >
              {toggleAddNotes ? 'Kembali' : 'Seterusnya'}
            </Button>
            {
              toggleAddNotes &&
              <Button
                onClick={() => handleSubmit()}
              >
                Hantar
              </Button>
            }
          </div>
        </div>
        {
            toggleAddNotes ? 
            <div style={{ display: 'flex', flexDirection: 'column', gap: 20, margin: '0 20px 0 20px' }}>
              <div>
                <Typography className={classes.body}>Nota tambahan</Typography>
                <TextareaAutosize
                  className={classes.textInput}
                  value={notes}
                  onChange={(val) => {setNotes(val.target.value)}}
                  minRows={5}
                  style={{ width: '100%', marginTop: 10, border: 0 }}
                />
              </div>
            </div> :
            <div style={{ display: 'flex', flexDirection: 'column', gap: 20, margin: '0 20px 0 20px' }}>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography className={classes.body} style={{ width: 100 }}>Koordinat</Typography>
                <span>
                  {`${newLayerCoordinate[0].toFixed(8)},
                  ${newLayerCoordinate[1].toFixed(8)}`}
                </span>
              </div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography className={classes.body} style={{ width: 100 }}>Jenis</Typography>
                <Select
                  displayEmpty
                  disableUnderline
                  className={classes.textInput}
                  value={selectedNewLayerTypeId}
                  onChange={(e) => setSelectedNewLayerTypeId(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    Pilih
                  </MenuItem>
                  {
                    selectableLayerTypes.map((selectableLayerType, index) => (
                      <MenuItem
                        key={index}
                        value={selectableLayerType.id}
                      >
                        {selectableLayerType.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography className={classes.body} style={{ width: 100 }}>Tajuk</Typography>
                <TextField
                  placeholder="Tajuk"
                  className={classes.textInput}
                  InputProps={{ disableUnderline: true }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{marginBottom: "20px"}}
                />
              </div>
              <Typography className={classes.body}>Gambar-gambar</Typography>
              <FilesDropzone
                onChangeFiles={setUploadedImages}
                accept='image/*'
              />
            </div>
        }
      </div>

    </Modal>
  )
}

export default NewLayerModal;