import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Route, Redirect, withRouter } from "react-router-dom";
import styles from "../styles";
import NavBar from "./Navbar";
import Map from "./Map";
import { AuthContext } from "../contexts/AuthContext";
import MapControls from "./MapControls";
import MapLoadingSpinner from "./MapLoadingSpinner";

const useStyles = makeStyles(styles);

const PrivateRoute = ({ withNavbar, withMap, component: Component, ...rest }) => {
  const classes = useStyles();
  const { isAuthenticated, autoLogin } = useContext(AuthContext);

  useEffect(() => {
    autoLogin();
  }, []);
  
  return (
    <div className={classes.root}>
      <div className={classes.privateMain}>
        {
          !withNavbar ? (
            <Route
              {...rest}
              render={(props) =>
                isAuthenticated ? (
                  <Component {...props} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: { from: props.location },
                    }}
                  />
                )
              }
            />
          ) : (
            <>
              <NavBar />
              {
                !withMap ? (
                  <Route
                    {...rest}
                    render={(props) =>
                      isAuthenticated ? (
                        <Component {...props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: "/login",
                            state: { from: props.location },
                          }}
                        />
                      )
                    }
                  />
                ) : (
                  <div className={classes.page}>
                    <Map />
                    <MapControls />
                    <MapLoadingSpinner />
                    <div className={classes.sideContainer}>
                      <Route
                        {...rest}
                        render={(props) =>
                          isAuthenticated ? (
                            <Component {...props} />
                          ) : (
                            <Redirect
                              to={{
                                pathname: "/login",
                                state: { from: props.location },
                              }}
                            />
                          )
                        }
                      />
                    </div>
                  </div>
                )
              }
            </>
          )
        }
      </div>
    </div>
  )
};

export default withRouter(PrivateRoute);
